import React, { ReactNode } from "react";
import { useEffect, useState } from "react";
import { useSuspenseQuery } from "@tanstack/react-query";
import { createFileRoute, Link, useNavigate } from "@tanstack/react-router";
import { AxiosError } from "axios";
import { toast } from "sonner";
import { z } from "zod";

import { TicketSortFields, TicketStatus } from "~/api/model";
import { TicketModelWithOrderData } from "~/api/model/ticketModelWithOrderData";
import { getGetAllTicketsV1TicketsGetSuspenseQueryOptions, useUpdateTicketByIdV1TicketsTicketIdPatch } from "~/api/tickets/tickets.gen";
import formatDate from "~/helpers/date-formatting";
import { parseFastAPIError } from "~/helpers/parse-errors";
import Button from "~/oldComponents/button/Button";
import { TextArea } from "~/oldComponents/form/TextArea";
import { ListPage } from "~/oldComponents/layout/ListPage";
import { FormConfig, PageConfig, PaginationConfig, TableConfig } from "~/oldComponents/layout/types";
import { Select } from "~/oldComponents/select/Select";
import { useAuthSelector } from "~/providers/auth";

export const Route = createFileRoute("/_auth/tickets")({
  validateSearch: z.object({
    status: z.enum([TicketStatus.Completed, TicketStatus.In_Progress, TicketStatus.Unverified, TicketStatus.All]).catch(TicketStatus.All),
    offerId: z.optional(z.string().min(1)).catch(undefined),
    completedBy: z.optional(z.string().min(1)).catch(undefined),
    page: z.number().default(1),
    limit: z.number().default(100),
    sortAscending: z.boolean().default(false),
    sortField: z.enum([TicketSortFields.createdAt, TicketSortFields.completedAt]).default(TicketSortFields.createdAt),
  }),
  loaderDeps: ({ search: { status, offerId, completedBy, page, limit, sortAscending, sortField } }) => ({
    status: status,
    offerId: offerId,
    completedBy: completedBy,
    page: page,
    limit: limit,
    sortAscending: sortAscending,
    sortField: sortField,
  }),
  loader: ({ context: { queryClient }, deps: { status, offerId, completedBy, page, limit, sortAscending, sortField } }) => {
    {
      return queryClient.ensureQueryData(
        getGetAllTicketsV1TicketsGetSuspenseQueryOptions({
          status: status === TicketStatus.All ? undefined : status,
          offer_id: offerId,
          completed_by: completedBy,
          sort_field: sortField,
          sort_ascending: sortAscending,
          page: page,
          limit: limit,
        }),
      );
    }
  },
  component: () => <CustomerSupportPage />,
});

function CustomerSupportPage() {
  const user = useAuthSelector((context) => context?.user);
  const searchParams = Route.useSearch();
  const navigate = useNavigate({ from: Route.fullPath });

  const { data, isFetching, isSuccess, refetch } = useSuspenseQuery(
    getGetAllTicketsV1TicketsGetSuspenseQueryOptions({
      status: searchParams.status === TicketStatus.All ? undefined : searchParams.status,
      offer_id: searchParams.offerId,
      completed_by: searchParams.completedBy,
      sort_field: searchParams.sortField,
      sort_ascending: searchParams.sortAscending,
      page: searchParams.page,
      limit: searchParams.limit,
    }),
  );

  useEffect(() => {
    if (data) {
      setTotal(data.total);
      setHasNext(data.has_next);
      setHasPrevious(data.has_prev);
    }
  }, [isSuccess]);

  const [total, setTotal] = useState<number>(0);
  const [hasNext, setHasNext] = useState<boolean>(false);
  const [hasPrevious, setHasPrevious] = useState<boolean>(false);

  const updateTicketMutation = useUpdateTicketByIdV1TicketsTicketIdPatch({
    mutation: {
      onSuccess: function () {
        toast("Ticket was successfully updated!");
        refetch();
      },
      onError: (error: AxiosError) => {
        toast.error("Error", { description: parseFastAPIError(error) as ReactNode });
      },
    },
  });

  const onStatusChange = async (event: React.ChangeEvent<HTMLSelectElement>, ticket: TicketModelWithOrderData) => {
    event.preventDefault();
    const status = event.target.value;

    await updateTicketMutation.mutateAsync({
      ticketId: ticket.id as string,
      data: {
        status: status as TicketStatus,

        completed_by: status === "Completed" ? user?.user_id : undefined,

        completed_at: status === "Completed" ? new Date().toISOString() : undefined,
      },
    });
  };

  const onCommentChange = async (event: React.MouseEvent<HTMLButtonElement>, ticket: TicketModelWithOrderData, ticketId: string) => {
    event.preventDefault();
    const commentField = document.querySelector(`[name="${event.currentTarget.dataset.id}"`); // Noqa
    const comment = (commentField as HTMLTextAreaElement).value;

    if (comment === ticket.comments) return;

    await updateTicketMutation.mutateAsync({
      ticketId: ticketId,
      data: { comments: comment },
    });
  };

  const onSortHandle = (field: string, sortAscending: boolean) => {
    navigate({ search: (prev) => ({ ...prev, page: 1, sortField: field as TicketSortFields, sortAscending: sortAscending }) });
  };

  const page: PageConfig = {
    title: "Tickets management",
    description: "Here you can find all orders that did not get a coupon code and handle them.",
    tableUpload: {
      fileName: "customer-support",
      data: data?.items || [],
    },
  };

  const table: TableConfig = {
    tableBuild: [
      {
        headerTitle: "Created at",
        format: (item: TicketModelWithOrderData) => formatDate(item.createdAt),
        sortFunction: () => onSortHandle("createdAt", searchParams.sortField === "createdAt" ? !searchParams.sortAscending : true),
      },
      {
        headerTitle: "Offer Id",
        format: (item: TicketModelWithOrderData) =>
          item.order && (
            <Link
              className="link"
              target="_blank"
              to={`/${item.order.offerSnapshot?.country || user?.country}/marketplace/rewards/${item.order.offerId}`}
            >
              {item.order.offerId} <br />
              {item.order.offerSnapshot?.titleV2} - {item.order.offerSnapshot?.subTitle}
            </Link>
          ),
      },
      {
        headerTitle: "User Id",
        format: (item: TicketModelWithOrderData) =>
          item.order.userId && (
            <Link className="link" target="_blank" to={`/users/${item.order.userId}`}>
              {item.order.userId}
              <br />
              {item.order.profileSnapshot.email}
            </Link>
          ),
      },
      {
        headerTitle: "Comments",
        format: (item: TicketModelWithOrderData) => (
          <div className="table__edit">
            <TextArea key={item.id} name={`comment-${item.id}`} value={item.comments as string} />
            <Button
              size="small"
              data-id={`comment-${item.id}`}
              onClick={(e) => onCommentChange(e, item, item.id as string)}
              icon="FaFloppyDisk"
              title="Save comment"
            ></Button>
          </div>
        ),
      },
      {
        headerTitle: "Status",
        actionRow: true,
        format: (item: TicketModelWithOrderData) => (
          <Select
            selectedValue={item.status.toString()}
            optionList={[
              {
                name: "Completed",
                value: TicketStatus.Completed,
              },
              {
                name: "In Progress",
                value: TicketStatus.In_Progress,
              },
              {
                name: "Unverified",
                value: TicketStatus.Unverified,
              },
            ]}
            onChange={(e) => onStatusChange(e, item)}
          />
        ),
      },
      {
        headerTitle: "Completed by",
        format: (item: TicketModelWithOrderData) => item.completedBy || "-",
      },
      {
        headerTitle: "Completed at",
        format: (item: TicketModelWithOrderData) => (item.completedAt ? formatDate(item.completedAt) : "-"),
        sortFunction: () => onSortHandle("completedAt", searchParams.sortField === "completedAt" ? !searchParams.sortAscending : true),
      },
    ],
    objects: data?.items || [],
    emptyMessage: "There are no tickets in the system yet.",
  };

  const onSearchSubmit = (event: React.FormEvent | undefined) => {
    event?.preventDefault();
  };

  const searchBar: FormConfig = {
    name: "tickets-searchbar",
    fields: [
      {
        name: "status",
        label: "Status:",
        helpText: "Search by tickets status.",
        type: "select",
        change: (e: React.ChangeEvent<HTMLSelectElement>) =>
          navigate({
            search: (prev) => ({ ...prev, status: e.target.value as TicketStatus }),
          }),
        value: searchParams.status,
        options: [
          {
            name: "All",
            value: TicketStatus.All,
          },
          {
            name: "Completed",
            value: TicketStatus.Completed,
          },
          {
            name: "In Progress",
            value: TicketStatus.In_Progress,
          },
          {
            name: "Unverified",
            value: TicketStatus.Unverified,
          },
        ],
      },
      {
        name: "offerId",
        label: "Offer Id:",
        type: "text",
        helpText: "Search by offer Id.",
        value: searchParams.offerId,
        change: (e: React.ChangeEvent<HTMLInputElement>) =>
          navigate({ search: (prev) => ({ ...prev, offerId: e.target.value.trim() || undefined }) }),
      },
      {
        name: "completedBy",
        label: "Completed by:",
        type: "text",
        helpText: "Search on the user who completed the ticket via their id.",
        value: searchParams.completedBy,
        change: (e: React.ChangeEvent<HTMLInputElement>) =>
          navigate({ search: (prev) => ({ ...prev, completedBy: e.target.value.trim() || undefined }) }),
      },
    ],
    onSubmit: onSearchSubmit,
    submitText: "Search",
  };

  const onPageChange = (page: number) => {
    navigate({ search: (prev) => ({ ...prev, page: page }) });
  };

  const onLimitChange = (limit: number) => {
    navigate({ search: (prev) => ({ ...prev, limit: limit }) });
  };

  const pagination: PaginationConfig = {
    page: {
      value: searchParams.page,
      onChange: onPageChange,
    },
    limit: {
      value: searchParams.limit,
      onChange: onLimitChange,
    },
    hasNext: hasNext,
    hasPrevious: hasPrevious,
    total: total,
  };

  return <ListPage form={searchBar} loading={isFetching} page={page} pagination={pagination} table={table} />;
}
