import React from "react";
import { memo } from "react";

import { DataOptions, LinkOptions } from "../layout/types";
import Loader from "../loader/Loader";

import TableHeader from "./TableHeader";
import TableRow from "./TableRow";
import { TableBuilder } from "./types";

import "./table.scss";

type OwnProps = {
  tableConfig: TableBuilder[];
  objects: DataOptions;
  link?: LinkOptions;
  emptyMessage?: string;
  tableTitle?: string;
  tableStyles?: string;
  loading?: boolean;
  id?: string;
};

type Props = OwnProps & React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;

export const OldTable = memo(({ tableConfig, objects, link, emptyMessage, tableTitle, tableStyles, id, loading }: Props) => {
  return (
    <section className={tableStyles}>
      {tableTitle && <h2 className="table__title">{tableTitle}</h2>}

      <table className="table">
        <tbody className="table__body">
          <tr className="table__row border-b">
            {tableConfig.map((config) => {
              return <TableHeader config={config} key={`table-header-${config.headerTitle}-${id}`} />;
            })}
          </tr>
          {loading && (
            <tr className="table__row border-b">
              <td colSpan={tableConfig.length} className="table__cell table__cell--loading">
                <Loader page={false} />
              </td>
            </tr>
          )}
          {!loading && (
            <TableRow objects={objects} link={link as LinkOptions | undefined} tableConfig={tableConfig} emptyMessage={emptyMessage} key={id} />
          )}
        </tbody>
      </table>
    </section>
  );
});
OldTable.displayName = "Table";
