import React from "react";

import { Form } from "../form/Form";

import PageLayout from "./PageLayout";
import { FormConfig, PageConfig } from "./types";

type Props = {
  loading: boolean;
  page: PageConfig;
  form: FormConfig;
};

export const CreateEditPage = ({ page, form, loading }: Props) => {
  return (
    <PageLayout title={page.title} description={page.description} addLink={page.addLink} onDeleteClick={page.onDelete} loading={loading}>
      {!loading && (
        <Form
          name={form.name}
          fields={form.fields}
          onSubmit={form.onSubmit}
          submitText={form.submitText}
          vertical={form.vertical}
          showStayOnPage={form.showStayOnPage}
        />
      )}
    </PageLayout>
  );
};
