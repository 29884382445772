/**
 * Generated by orval
 * Do not edit manually.
 * Stepler API for the Admin
 * This is the documentation for the Stepler Admin.
 * OpenAPI spec version: 1.0.0
 */

export type CampaignStatus = typeof CampaignStatus[keyof typeof CampaignStatus];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CampaignStatus = {
  Active: 'Active',
  Inactive: 'Inactive',
  Deleted: 'Deleted',
} as const;
