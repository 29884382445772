import React from "react";
import { useState } from "react";

import { Icon } from "@/icon/icon";

import { Route } from "../../routes_old";

import { RoutedLink } from "./RoutedLink";

import { useAuthSelector } from "~/providers/auth";

type Props = {
  menuOpened: boolean;
  route: Route;
};

export const Nestedroute = ({ menuOpened, route }: Props) => {
  const [subMenuOpened, setSubMenuOpened] = useState(false);
  const roles = useAuthSelector((context) => context?.roles || []);

  if (!roles.includes(route.role)) return null;

  return (
    <>
      <div className={`group bg-sidebar hover:relative ${menuOpened ? "" : "hover:w-72"}`} onClick={() => setSubMenuOpened(!subMenuOpened)}>
        <div
          className={`flex ${menuOpened ? "gap-2" : ""} items-center border-l-8 border-transparent bg-sidebar hover:relative hover:border-primary`}
        >
          <div className="px-4 py-5">
            <Icon icon={route.icon} />
          </div>
          <div className={`bg-sidebar ${menuOpened ? "py-4 pr-4" : "z-10 hidden p-4 group-hover:block"} flex-grow text-sm`}>{route.name}</div>
          {menuOpened && <div className="p-4">{menuOpened && (subMenuOpened ? <Icon icon="ArrowDown" /> : <Icon icon="ArrowRight" />)}</div>}
        </div>
        {/* position: absolute; width: calc(100% - 55px); display: none; top: 100%; left: 55px; background-color: var(--navbar-hover-color); */}
        {!menuOpened && (
          <section className="absolute left-16 top-full z-10 hidden w-56 bg-sidebar group-hover:block">
            {route.nested?.map((route, i) => <RoutedLink key={i} route={route as Route} menuOpened={menuOpened} />)}
          </section>
        )}
      </div>
      {menuOpened && subMenuOpened && (
        <div className="nested-nav-item_dropdown">
          {route.nested?.map((route, i) => <RoutedLink key={i} route={route as Route} menuOpened={menuOpened} />)}
        </div>
      )}
    </>
  );
};
