import React, { ReactNode } from "react";

import { DataOption, DataOptions, LinkOptions } from "../layout/types";

import { TableBuilder } from "./types";

type OwnProps = {
  tableConfig: TableBuilder[];
  objects: DataOptions;
  link?: LinkOptions;
  emptyMessage?: string;
  tableTitle?: string;
  tableStyles?: string;
  id?: string;
};

type Props = OwnProps & React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;

const TableRow = ({ objects, link, tableConfig, emptyMessage, id }: Props) => {
  const renderRow = (item: DataOption, config: TableBuilder, index: number, link?: LinkOptions) => {
    // @ts-expect-error item has some problems but is working
    const data = config.format(item) || "";

    const title = data;

    if (link && !config?.actionRow) {
      return (
        // @ts-expect-error item has some problems but is working
        <td onClick={() => link(item)} key={`table-cell-${index}-${id}`} className="table__cell table__cell--pointer" title={title.toString()}>
          {data as ReactNode}
        </td>
      );
    } else {
      return (
        <td key={`table-cell-${index}-${id}`} className="table__cell" title={title.toString()}>
          {data as ReactNode}
        </td>
      );
    }
  };

  if (!objects || objects.length < 1) {
    return (
      <tr className="table__row border-b hover:bg-background/50" role="table" key="empty">
        <td className="table__cell" colSpan={100}>
          {emptyMessage ? emptyMessage : "No results found. Please adjust the search parameters or start searching"}
        </td>
      </tr>
    );
  } else {
    return (
      <>
        {objects?.map((item, i) => {
          return (
            <tr
              className={`table__row border-b hover:bg-background/50`}
              key={`table-row-${i}-${typeof item !== "string" && "_id" in item ? item._id : ""}`}
              role="table"
            >
              {tableConfig.map((config, j) => {
                return renderRow(item, config, j, link);
              })}
            </tr>
          );
        })}
      </>
    );
  }
};

export default TableRow;
