import React from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

import { Icon } from "@/icon/icon";
import { Badge } from "@/ui/badge";
import { Button, buttonVariants } from "@/ui/button";

import { Category } from "~/api/model";
import { getCategoryIcon, getCategoryName } from "~/helpers/category";

type SortableCategoryProps = {
  id: string;
  categoryType: Category;
  rewardOrderCount: number;
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
};

export function SortableCategory(props: SortableCategoryProps) {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: props.id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition: transition,
  };

  return (
    <div
      ref={setNodeRef}
      data-rewards={`rewards-${props.rewardOrderCount}`}
      className="group relative flex flex-col items-center gap-2 text-center data-[rewards=rewards-0]:opacity-50"
      style={style}
      {...attributes}
      {...listeners}
    >
      <div className={buttonVariants({ variant: "accent", className: "flex h-28 w-20 flex-col gap-2 p-4" })}>
        <div>
          <Button variant="default" className="absolute left-2 top-2 hidden p-1 group-hover:inline-block" onClick={props.onClick}>
            <Icon icon="Edit" />
          </Button>
        </div>
        <Icon icon={getCategoryIcon(props.categoryType)} className="h-8 w-8" />
        <span className="whitespace-normal text-[10px]">{getCategoryName(props.categoryType) || props.id}</span>
      </div>
      <Badge>{props.rewardOrderCount}</Badge>
    </div>
  );
}
