import React from "react";
import { IconType } from "react-icons";
import * as faIcon from "react-icons/fa6";

interface IconProps {
  icon: string;
  className?: string;
  onClick?: () => void;
  title?: string;
  spanLess?: boolean;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const ICONS: any = {
  ...faIcon,
};

const getIcon = (icon: string, className: string | undefined, onClick?: (event?: React.MouseEvent) => void) => {
  if (!icon) return <></>;
  const IconComponent: IconType = ICONS[icon];
  if (!IconComponent) return <></>;
  return <IconComponent className={className} onClick={onClick} />;
};

const Icon: React.FC<IconProps> = ({ icon, className, title, onClick, spanLess }: IconProps) => {
  if (spanLess) {
    return getIcon(icon, className, onClick);
  }
  return (
    <span data-title={title} onClick={onClick}>
      {getIcon(icon, className)}
    </span>
  );
};

export default Icon;
