import React from "react";
import { memo } from "react";
import PropTypes from "prop-types";

import "./input.scss";

type OwnProps = {
  wrapperClassName?: string;
};

type Props = OwnProps & React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;

export const Input = memo(({ name, value, type, disabled, ...restProps }: Props) => {
  if (!value) {
    value = "";
  }

  return (
    <input
      id={name}
      name={name}
      type={type}
      defaultValue={value !== undefined ? value : undefined}
      disabled={disabled}
      {...restProps}
      className={"input bg-background text-foreground autofill:bg-background autofill:text-foreground"}
    />
  );
});
// @ts-expect-error memo proptypes problem
Input.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  type: PropTypes.string,
  disabled: PropTypes.bool,
};
Input.displayName = "Input";
