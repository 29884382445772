import React from "react";

import steplerIcon from "../../assets/icons/stepler.png";

const SplashPage = () => {
  return (
    <section className="flex h-screen w-full items-center justify-center bg-background">
      <img className="animate-pulse" src={steplerIcon} />
    </section>
  );
};

export default SplashPage;
