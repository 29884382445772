import { z } from "zod";

import { UserStatus } from "~/api/model";

export const userFormSchema = z.object({
  user_id: z.string().optional(),
  email: z.string().optional(),
  first_name: z.string().optional(),
  last_name: z.string().optional(),
  phonenumber: z.string().optional(),
  postal_code: z.string().optional(),
  street: z.string().optional(),
  city: z.string().optional(),
  country: z.string().nullable().optional().catch(null),
  is_cheater: z.boolean().optional(),
  is_possible_cheater: z.boolean().optional(),
  status: z.enum([UserStatus.Active, UserStatus.Deleted]).optional(),
  sort_field: z.string().optional().catch("score"),
  sort_order: z.number().optional().catch(-1),
  page: z.number().catch(1),
  limit: z.number().catch(100),
});

export type UserSearch = z.infer<typeof userFormSchema> & { _id?: string };
