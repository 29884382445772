import React from "react";

export type Error = {
  loc: string[];
  msg: string;
};

export const getMessage = (detail: Error[]) => {
  return (
    <div>
      {detail.map((error: Error) => (
        <div key={error.loc.join(".")}>{`${error.loc.join(".")}: ${error.msg}`}</div>
      ))}
    </div>
  );
};
