import React from "react";
import { memo } from "react";
import { Link as TanLink, ToPathOption } from "@tanstack/react-router";
import clsx from "clsx";

import Icon from "../icon/Icon";
import Loader from "../loader/Loader";

import "./button.scss";

interface OwnProps {
  to: ToPathOption;
  styleType?: "success" | "danger" | "secondary";
  loading?: boolean;
  size?: "small" | "medium" | "large";
  children?: React.ReactNode;
  title?: string;
  confirmMessage?: string;
  icon?: string;
}

type Props = OwnProps;

const Link = memo(({ children, to, styleType, loading, icon, size = "medium", title }: Props) => {
  // const handleClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
  //   if (!confirmMessage) {
  //     return;
  //   }
  //   const shouldNavigate = window.confirm(confirmMessage);
  //   if (!shouldNavigate) {
  //     event.preventDefault();
  //   }
  // };

  const classes = clsx({
    button: true,
    "button--secondary": styleType === "secondary",
    "button--success": styleType === "success",
    "button--danger": styleType === "danger",
    "button--small": size === "small",
    "button--large": size === "large",
    "button--icon": icon,
  });

  return (
    <TanLink to={to} className={classes} title={title}>
      {loading && <Loader page={false} />}
      {!loading && children}
      {icon && <Icon icon={icon} />}
    </TanLink>
  );
});
Link.displayName = "Link";

export default Link;
