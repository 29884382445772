import * as Sentry from "@sentry/react";
import { AxiosError } from "axios";

import { Error, getMessage } from "./errors";

const parseFastAPIError = (error: AxiosError) => {
  // Log that there was an error
  Sentry.captureException(error);

  const { response } = error;
  if (!response) return "Something went wrong!";

  const data = response.data as { detail: Error[] } | null;
  if (!data) return "Something went wrong!";

  const detail = data.detail;
  if (response.status === 422) {
    return getMessage(detail);
  }

  if (response.status === 404) {
    return detail;
  }

  if (response.status === 400) {
    return detail;
  }

  return "Something went wrong!";
};

export { parseFastAPIError };
