import React, { memo, useCallback, useMemo } from "react";
import { useRouterState } from "@tanstack/react-router";

import { BackendSelector } from "@/backend_change";
import { Icon } from "@/icon/icon";
import { ScrollArea } from "@/ui/scroll-area";

import { CustomRoute, externalRoutes, Route, routes } from "../../routes_old";

import { Nestedroute } from "./NestedRoute";
import { RoutedLink } from "./RoutedLink";

import { useAuthSelector } from "~/providers/auth";
import { useThemeSelector } from "~/providers/theme";

type Props = {
  menuOpened: boolean;
};

export const Sidebar = memo(({ menuOpened }: Props) => {
  const logout = useAuthSelector((context) => context?.logout);
  const user = useAuthSelector((context) => context?.user);
  const theme = useThemeSelector((context) => context.theme);
  const setTheme = useThemeSelector((context) => context.setTheme);
  const router = useRouterState();
  const params =
    router.matches.length > 0 ? (router.matches[router.matches.length - 1].params as { market: string }) : { market: user?.country || "SE" };

  const extraRoutes = useMemo(
    () => [
      ...(user
        ? [
            {
              name: "My Profile",
              role: "ANY",
              icon: "FaClipboardUser",
              to: `/users/${user.user_id}`,
            } as CustomRoute,
          ]
        : []),
      {
        name: "Change Theme",
        role: "ANY",
        icon: theme === "light" ? "Sunny" : "DarkMode",
        onClick: () => setTheme(theme === "light" ? "dark" : "light"),
      } as CustomRoute,
      {
        name: `Version: ${APP_VERSION}`,
        role: "ANY",
        icon: "FaCodeBranch",
      } as CustomRoute,
    ],
    [user, theme, setTheme],
  );

  const getRoute = useCallback(
    (route: Route | CustomRoute, index: number) => {
      if ("nested" in route && route.nested?.length) {
        return <Nestedroute key={`nested-route-${index}`} menuOpened={menuOpened} route={route} />;
      }
      return <RoutedLink key={`routed-link-${index}`} route={route} menuOpened={menuOpened} />;
    },
    [menuOpened],
  );

  return (
    <ScrollArea className="flex h-pageHeight flex-col gap-2 bg-sidebar">
      {routes.map((route, index) => {
        if (route.name === "Marketplace") route.to = route.to.replace("PLACE_MARKET", params.market ? params.market : user?.country || "SE");
        return getRoute(route, index);
      })}
      <hr></hr>
      {externalRoutes.map((route, index) => {
        return getRoute(route, index);
      })}
      <hr></hr>
      {extraRoutes.map((route, index) => {
        return getRoute(route, index);
      })}
      <div
        className={`flex ${menuOpened ? "gap-2" : "group whitespace-nowrap"} items-center border-l-8 border-transparent bg-sidebar hover:relative hover:border-primary`}
        title="Logout"
        onClick={logout}
      >
        <div className="px-4 py-5">
          <Icon icon="LogOut" />
        </div>

        <div className={`bg-sidebar ${menuOpened ? "py-4 pr-4" : "hidden p-4 group-hover:block"} text-sm`}>Logout</div>
      </div>
      <BackendSelector className="mx-2 text-xs" />
    </ScrollArea>
  );
});
Sidebar.displayName = "Sidebar";
