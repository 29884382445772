import React, { useEffect, useMemo } from "react";
import { QueryClient } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { createRootRouteWithContext, Outlet } from "@tanstack/react-router";
import { AxiosError, AxiosInterceptorManager, AxiosResponse, InternalAxiosRequestConfig } from "axios";
import { jwtDecode, JwtPayload } from "jwt-decode";

import { TanStackRouterDevtools } from "@/TanStackRouterDevtools";
import { Toaster } from "@/ui/sonner";

import { refreshTokenCall } from "~/api/auth";
import { AXIOS_INSTANCE } from "~/api/base";
import { AuthContext, StoredData, useAuthSelector } from "~/providers/auth";
import { ThemeProvider } from "~/providers/theme";

export const Route = createRootRouteWithContext<{
  auth: AuthContext;
  queryClient: QueryClient;
}>()({
  component: () => <Root />,
});

function Root() {
  const logout = useAuthSelector((context) => context!.logout);
  const setAuth = useAuthSelector((context) => context!.setAuth);
  const refreshToken = useAuthSelector((context) => context?.refreshToken);
  const accessToken = useAuthSelector((context) => context?.accessToken);
  const [refreshingToken, setRefreshingToken] = React.useState(false);

  useEffect(() => {
    document.title = `Stepler Admin - ${import.meta.env.VITE_REACT_APP_NODE_ENV}`;

    const requestHandlers = AXIOS_INSTANCE.interceptors.request as AxiosInterceptorManager<InternalAxiosRequestConfig>;
    // @ts-expect-error handlers is an internal axios thing
    if (requestHandlers.handlers.length === 0) {
      AXIOS_INSTANCE.interceptors.request.use(
        async (config) => {
          if (refreshToken && accessToken) {
            const decoded: JwtPayload = jwtDecode(accessToken);
            config.headers.set("userid", decoded.iss as string);
            if (decoded.exp && decoded.exp * 1000 <= Date.now()) {
              const response = await refreshAuthToken();
              config.headers.setAuthorization(`Bearer ${response.access_token}`);
            } else {
              config.headers.setAuthorization(`Bearer ${accessToken}`);
            }
          }
          return config;
        },
        (error) => {
          Promise.reject(error);
        },
      );
    }

    const responseHandlers = AXIOS_INSTANCE.interceptors.response as AxiosInterceptorManager<AxiosResponse>;
    // @ts-expect-error handlers is an internal axios thing
    if (responseHandlers.handlers.length === 0) {
      AXIOS_INSTANCE.interceptors.response.use(
        (response) => {
          return response;
        },
        async function (error: AxiosError) {
          const originalRequest = error.config;
          if (!error.response || !originalRequest) {
            const response = await refreshAuthToken();
            AXIOS_INSTANCE.defaults.headers.common.Authorization = `Bearer ${response.access_token}`;
          } else if (error.response.status === 401) {
            const response = await refreshAuthToken();
            AXIOS_INSTANCE.defaults.headers.common.Authorization = `Bearer ${response.access_token}`;
            return AXIOS_INSTANCE(originalRequest);
          }
          return Promise.reject(error);
        },
      );
    }
  }, []);

  const refreshAuthToken = async () => {
    if (!refreshingToken && refreshToken) {
      try {
        const decoded: JwtPayload = jwtDecode(refreshToken);
        setRefreshingToken(true);

        const response = await refreshTokenCall({ refreshToken: refreshToken, userId: decoded.iss as string });
        setAuth({ accessToken: response.access_token, refreshToken: response.refresh_token, user: response.user } as StoredData);
        return response;
      } catch {
        logout();
      } finally {
        setRefreshingToken(false);
      }
    }
  };

  const tanStackRouterDevtools = useMemo(() => <TanStackRouterDevtools />, []);

  return (
    <>
      <ThemeProvider defaultTheme="dark" storageKey="vite-ui-theme">
        <Outlet />
        <Toaster />
      </ThemeProvider>
      <ReactQueryDevtools />
      {tanStackRouterDevtools}
    </>
  );
}
