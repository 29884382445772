import React from "react";
import { memo } from "react";
import PropTypes from "prop-types";

import { Option } from "../form/types";

import "./select.scss";

type OwnProps = {
  empty?: string;
  optionList: Option[];
  selectedValue: number | string;
};

type Props = OwnProps & React.DetailedHTMLProps<React.SelectHTMLAttributes<HTMLSelectElement>, HTMLSelectElement>;

export const Select = memo(({ name, optionList, selectedValue, empty, className, ...selectProps }: Props) => {
  return (
    <select
      key={`select-${name}`}
      name={name}
      className={`select ${className} bg-background p-4 text-foreground`}
      {...selectProps}
      defaultValue={selectedValue}
    >
      {empty && <option value="">{empty}</option>}
      {optionList.map((option) => (
        <option key={`${name}-${option.value}`} value={option.value as string}>
          {option.name}
        </option>
      ))}
    </select>
  );
});
// @ts-expect-error Proptypes does not exist on MemoExoticComponent
Select.propTypes = {
  name: PropTypes.string,
  className: PropTypes.string,
};
Select.displayName = "Select";
