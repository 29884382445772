/**
 * Generated by orval
 * Do not edit manually.
 * Stepler API for the Admin
 * This is the documentation for the Stepler Admin.
 * OpenAPI spec version: 1.0.0
 */

export type CouponCodeStatus = typeof CouponCodeStatus[keyof typeof CouponCodeStatus];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CouponCodeStatus = {
  Used: 'Used',
  Unused: 'Unused',
} as const;
