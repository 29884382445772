import * as React from "react";
import { useEffect, useState } from "react";
import { QueryObserverResult, RefetchOptions, useSuspenseQuery } from "@tanstack/react-query";
import { isAfter, parseISO, subMonths } from "date-fns";
import { toast } from "sonner";

import { IconName } from "@/icon/types";

import { getGetMarketplaceDataV1MarketplaceGetSuspenseQueryOptions } from "~/api/marketplace/marketplace.gen";
import {
  BusinessRulesCollectionModel,
  BusinessRuleType,
  CategoryMarketModel,
  HighlightedCollectionModel,
  OfferStatus,
  OfferType,
  OfferWithCouponCount,
  RecommendedModel,
  TagModel,
} from "~/api/model";
import { getCategoryIcon, getCategoryName } from "~/helpers/category";

export type OfferWithCategoriesAndTags = OfferWithCouponCount & {
  id: string | undefined;
  fullTitle: string;
  categories: CategoryMarketModel[];
  tags: TagModel[];
  categoryIds: string[];
  categoryIdsString: string;
  tagIds: string[];
  tagNames: string;
  isBonus: boolean;
};
export type CategoryWithStatusAndRewardCount = CategoryMarketModel & {
  status: ["recommended", "highlighted"] | ["recommended"] | ["highlighted"] | [];
  rewardCount: number;
  publishedCount: number;
  unpublishedCount: number;
  name: string;
  icon: IconName;
  rewardOrder: string[];
};
export type TagWithStatusAndRewardCount = TagModel & {
  status: ["recommended", "highlighted"] | ["recommended"] | ["highlighted"] | [];
  rewardCount: number;
  publishedCount: number;
  unpublishedCount: number;
  rewardOrder: string[];
};

export type BusinessRuleWithStatusAndCount = BusinessRulesCollectionModel & {
  status: ["recommended", "highlighted"] | ["recommended"] | ["highlighted"] | [];
  rewardCount: number;
  publishedCount: number;
  unpublishedCount: number;
};

export type HighlightedCollectionWithCollection = HighlightedCollectionModel & {
  id: string | undefined;
  collection: CategoryWithStatusAndRewardCount | TagWithStatusAndRewardCount | BusinessRuleWithStatusAndCount | undefined;
};

interface MarketplaceContext {
  rewards: OfferWithCategoriesAndTags[];
  activeRewards: OfferWithCategoriesAndTags[];
  newUserSpecialRewards: OfferWithCategoriesAndTags[];
  almostSoldOutRewards: OfferWithCategoriesAndTags[];
  categories: CategoryWithStatusAndRewardCount[];
  tags: TagWithStatusAndRewardCount[];
  businessRules: BusinessRuleWithStatusAndCount[];
  recommended: RecommendedModel[];
  collections: HighlightedCollectionWithCollection[];
  market: string;
  refetchMarketplace: (options?: RefetchOptions | undefined) => Promise<QueryObserverResult<unknown, Error>>;
  marketplaceLoading: boolean;
}

const MarketplaceContext = React.createContext<MarketplaceContext | null>(null);

export function MarketplaceProvider({ children, market }: { children: React.ReactNode; market: string }) {
  const [rewards, setRewards] = useState<OfferWithCategoriesAndTags[]>([]);
  const [activeRewards, setActiveRewards] = useState<OfferWithCategoriesAndTags[]>([]);
  const [categories, setCategories] = useState<CategoryWithStatusAndRewardCount[]>([]);
  const [tags, setTags] = useState<TagWithStatusAndRewardCount[]>([]);
  const [collections, setCollections] = useState<HighlightedCollectionWithCollection[]>([]);
  const [businessRules, setBusinessRules] = useState<BusinessRuleWithStatusAndCount[]>([]);

  const { data, isLoading, refetch } = useSuspenseQuery(getGetMarketplaceDataV1MarketplaceGetSuspenseQueryOptions({ market: market }));

  function getCOrTStatus(identifier: string, colls: HighlightedCollectionModel[], recs: RecommendedModel[]) {
    const status = [];
    if (colls.find((c: HighlightedCollectionModel) => c.data === identifier)) {
      status.push("highlighted");
    }
    if (recs.find((r: RecommendedModel) => r.data === identifier)) {
      status.push("recommended");
    }

    return status;
  }

  function getBusinessRuleRewards(rule: BusinessRulesCollectionModel, rewards: OfferWithCategoriesAndTags[]) {
    if (rule.type === BusinessRuleType.NewUserSpecial) {
      console.log("getting the rewards for the business RUle");
      const brRewards = rewards.filter((r) => rule.rewardOrder?.includes(r.id as string) && r.type === OfferType.NewUserSpecialOffer);
      return brRewards.sort((a, b) => {
        const indexA = rule.rewardOrder?.indexOf(a.id as string) ?? -1;
        const indexB = rule.rewardOrder?.indexOf(b.id as string) ?? -1;
        return indexA - indexB;
      });
    } else if (rule.type === BusinessRuleType.All) {
      return rewards.filter((r) => r.status !== OfferStatus.Archived && r.type !== OfferType.NewUserSpecialOffer && !r.hideInList);
    } else if (rule.type === BusinessRuleType.MostSold) {
      return rewards
        .filter((r) => r.status === OfferStatus.Published && r.type !== OfferType.NewUserSpecialOffer && !r.hideInList)
        .sort((a, b) => (b.couponWarningLimit || 7) - (a.couponWarningLimit || 7))
        .slice(0, rule.amountOfRewards || 0);
    } else {
      return [];
    }
  }

  function getConnectedCollection(
    identifier: string,
    type: string,
    categories: CategoryWithStatusAndRewardCount[],
    tags: TagWithStatusAndRewardCount[],
    rules: BusinessRuleWithStatusAndCount[],
  ) {
    if (type === "Category") {
      return categories.find((c) => c.category === identifier);
    } else if (type === "Tag") {
      return tags.find((t) => t._id === identifier);
    } else if (type === "BusinessRules") {
      return rules.find((b) => b._id === identifier);
    } else {
      toast.info("Unknow collection type");
    }
  }

  useEffect(() => {
    const newRewards = data.offers.map((offer) => {
      const rewardCategories = data.categories.filter((category) => category.rewardOrder.includes(offer._id?.toString() || ""));
      const rewardTags = data.tags
        .filter((tag) => tag.rewardOrder?.includes(offer._id?.toString() || ""))
        .sort((a, b) => a.name.localeCompare(b.name));
      return {
        ...offer,
        id: offer._id,
        fullTitle: `${offer.titleV2} - ${offer.subTitle || ""}`,
        categories: rewardCategories,
        tags: rewardTags,
        categoryIds: rewardCategories.map((c) => c.category),
        categoryIdsString: rewardCategories.map((c) => c.category).join(","),
        tagIds: rewardTags.map((t) => t._id),
        tagNames: rewardTags.map((t) => t.name).join(","),
        isBonus: offer.type === OfferType.Bonus,
      } as OfferWithCategoriesAndTags;
    });
    const newCategories = data.categories
      .map((category) => {
        return {
          ...category,
          name: getCategoryName(category.category),
          icon: getCategoryIcon(category.category),
          status: getCOrTStatus(category.category, data.highlighted_collections, data.recommended),
          rewardCount: category.rewardOrder.length,
          publishedCount: newRewards.filter((reward) => reward.categoryIds.includes(category.category) && reward.status === OfferStatus.Published)
            .length,
          unpublishedCount: newRewards.filter(
            (reward) => reward.categoryIds.includes(category.category || "") && reward.status === OfferStatus.Unpublished,
          ).length,
        } as CategoryWithStatusAndRewardCount;
      })
      .sort((a, b) => a.category.localeCompare(b.category));
    const newTags = data.tags
      .map((tag) => {
        return {
          ...tag,
          status: getCOrTStatus(tag._id as string, data.highlighted_collections, data.recommended),
          rewardCount: tag.rewardOrder?.length || 0,
          publishedCount: newRewards.filter((reward) => reward.tagIds.includes(tag._id as string) && reward.status === OfferStatus.Published).length,
          unpublishedCount: newRewards.filter((reward) => reward.tagIds.includes(tag._id as string) && reward.status === OfferStatus.Unpublished)
            .length,
        } as TagWithStatusAndRewardCount;
      })
      .sort((a, b) => a.name.localeCompare(b.name));
    const newBusinessRules = data.business_rules.map((rule) => {
      const ruleRewards = getBusinessRuleRewards(rule, newRewards);

      return {
        ...rule,
        status: getCOrTStatus(rule._id as string, data.highlighted_collections, data.recommended),
        rewardCount: ruleRewards.length,
        publishedCount: ruleRewards.filter((reward) => reward.status === OfferStatus.Published).length,
        unpublishedCount: ruleRewards.filter((reward) => reward.status === OfferStatus.Unpublished).length,
        rewardOrder: ruleRewards.map((r) => r.id),
      } as BusinessRuleWithStatusAndCount;
    });
    const newCollections = data.highlighted_collections.map((highlightedCollection) => {
      return {
        ...highlightedCollection,
        collection: getConnectedCollection(highlightedCollection.data, highlightedCollection.type, newCategories, newTags, newBusinessRules),
        id: highlightedCollection._id,
      } as HighlightedCollectionWithCollection;
    });
    setRewards(newRewards);
    setActiveRewards(
      newRewards
        .filter((reward) => reward.status != OfferStatus.Archived && reward.type !== OfferType.NewUserSpecialOffer)
        .sort((a, b) => a.titleV2.localeCompare(b.titleV2)),
    );
    setCategories(newCategories);
    setTags(newTags);
    setBusinessRules(newBusinessRules);
    setCollections(newCollections);
  }, [data]);

  const oneMonthAgo = subMonths(new Date(), 1);

  return (
    <MarketplaceContext.Provider
      value={{
        rewards: rewards,
        activeRewards: activeRewards,
        newUserSpecialRewards: rewards.filter((r) => r.status !== OfferStatus.Archived && r.type === OfferType.NewUserSpecialOffer),
        almostSoldOutRewards: rewards
          .filter((reward) => reward.status !== OfferStatus.Archived)
          .filter((reward) => !reward.isBonus)
          .filter((reward) => reward.updatedAt && isAfter(parseISO(reward.updatedAt), oneMonthAgo))
          .filter(
            (reward) =>
              reward.amount - (reward.verified || 0) < (reward.couponWarningLimit || 7) ||
              (reward.coupon_count &&
                reward.coupon_count > 0 &&
                reward.unused_coupon_count &&
                reward.unused_coupon_count < (reward.couponWarningLimit || 7)),
          ),
        categories: categories,
        tags: tags,
        businessRules: businessRules,
        recommended: data.recommended || [],
        collections: collections,
        refetchMarketplace: refetch,
        marketplaceLoading: isLoading,
        market: market,
      }}
    >
      {children}
    </MarketplaceContext.Provider>
  );
}

export function useMarketplace() {
  const context = React.useContext(MarketplaceContext);
  if (!context) {
    throw new Error("useMarketplace must be used within an MarketplaceProvider");
  }
  return context;
}
