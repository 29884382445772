import React from "react";
import { memo } from "react";

import "./text-area.scss";

type Props = {
  value?: string;
} & React.DetailedHTMLProps<React.TextareaHTMLAttributes<HTMLTextAreaElement>, HTMLTextAreaElement>;

export const TextArea = memo(({ value, ...restProps }: Props) => {
  return (
    <div className="textarea">
      <textarea className="textarea__text-area" {...restProps} defaultValue={value} />
    </div>
  );
});
TextArea.displayName = "TextArea";
