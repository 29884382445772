import React, { ReactNode, useEffect, useState } from "react";
import { useSuspenseQuery } from "@tanstack/react-query";
import { createFileRoute, useNavigate } from "@tanstack/react-router";
import { AxiosError } from "axios";
import { toast } from "sonner";

import { ImageType, PartnerAppModel } from "~/api/model";
import {
  getGetPartnerAppByIdV1PartnerAppsPartnerAppIdGetSuspenseQueryOptions,
  useUpdatePartnerAppV1PartnerAppsPartnerAppIdPut,
} from "~/api/partner-app/partner-app.gen";
import { getCountryOptions } from "~/helpers/country-list";
import { parseFastAPIError } from "~/helpers/parse-errors";
import { CreateEditPage } from "~/oldComponents/layout/CreateEditLayout";
import { FormConfig, PageConfig } from "~/oldComponents/layout/types";

export const Route = createFileRoute("/_auth/partner-apps/$partnerAppId")({
  loader: ({ context: { queryClient }, params: { partnerAppId } }) => {
    return queryClient.ensureQueryData(getGetPartnerAppByIdV1PartnerAppsPartnerAppIdGetSuspenseQueryOptions(partnerAppId));
  },
  component: () => <UpdatePartnerAppPage />,
});

const UpdatePartnerAppPage = () => {
  const navigate = useNavigate();

  const partnerAppCampaignId = Route.useParams().partnerAppId;

  const [partnerAppCampaign, setPartnerAppCampaign] = useState<PartnerAppModel | undefined>(undefined);

  const { data: fetchedPartnerAppCampaign, isFetching } = useSuspenseQuery(
    getGetPartnerAppByIdV1PartnerAppsPartnerAppIdGetSuspenseQueryOptions(partnerAppCampaignId),
  );

  useEffect(() => {
    if (fetchedPartnerAppCampaign) {
      setPartnerAppCampaign(fetchedPartnerAppCampaign);
    }
  }, [fetchedPartnerAppCampaign]);

  const updatePartnerAppMutation = useUpdatePartnerAppV1PartnerAppsPartnerAppIdPut({
    mutation: {
      onSuccess: () => {
        toast("Partner app campaign was successfully updated!");
        navigate({ to: `/partner-apps?status=${partnerAppCampaign?.status}&country=${partnerAppCampaign?.country}` });
      },
      onError: (error: AxiosError) => {
        toast.error("Error", { description: parseFastAPIError(error) as ReactNode });
      },
    },
  });

  const onSubmit = async (event: React.FormEvent | undefined) => {
    event?.preventDefault();
    if (!partnerAppCampaign) return;

    await updatePartnerAppMutation.mutateAsync({
      partnerAppId: partnerAppCampaignId,
      data: partnerAppCampaign,
    });
  };

  const page: PageConfig = {
    title: "Edit partner app campaign",
    description: "Use this elements, if you want to show some hints or additional information",
  };

  const form: FormConfig = {
    fields: [
      {
        name: "country",
        label: "Country:",
        type: "search-select",
        helpText: "Specify campaign country.",
        change: (data: string) =>
          setPartnerAppCampaign((campaign) => {
            if (!campaign) return campaign;
            campaign.country = data;
            return campaign;
          }),
        value: partnerAppCampaign?.country,
        options: getCountryOptions(),
        empty: " ",
        row: true,
        required: true,
      },
      {
        name: "title",
        label: "Title:",
        type: "text",
        helpText: "Campaign title.",
        value: partnerAppCampaign?.title,
        change: (event: React.ChangeEvent<HTMLInputElement>) =>
          setPartnerAppCampaign((campaign) => {
            if (!campaign) return campaign;
            console.log(event);
            return {
              ...campaign,
              title: event.target.value,
            };
          }),
        row: true,
        required: true,
      },
      {
        name: "description",
        label: "Description:",
        type: "text-editor",
        helpText: "Campaign description.",
        value: partnerAppCampaign?.description,
        change: (data: string) => {
          console.log(data);
          setPartnerAppCampaign((campaign) => {
            if (!campaign) return campaign;
            return {
              ...campaign,
              description: data,
            };
          });
        },
        row: true,
        required: true,
        custom: true,
      },
      {
        name: "deepLink",
        label: "Deep link:",
        type: "text",
        helpText: "Campaign deep link.",
        value: partnerAppCampaign?.deepLink,
        change: (event: React.ChangeEvent<HTMLInputElement>) =>
          setPartnerAppCampaign((campaign) => {
            if (!campaign) return campaign;
            return {
              ...campaign,
              deepLink: event.target.value,
            };
          }),
        row: true,
        required: true,
      },
      {
        name: "reward",
        label: "Reward:",
        type: "number",
        helpText: "Reward points amount.",
        value: partnerAppCampaign?.reward,
        change: (event: React.ChangeEvent<HTMLInputElement>) =>
          setPartnerAppCampaign((campaign) => {
            if (!campaign) return campaign;
            return {
              ...campaign,
              reward: +event.target.value,
            };
          }),
        row: true,
        min: 1,
        required: true,
      },
      {
        name: "maxDownloads",
        label: "Max downloads:",
        type: "number",
        helpText: "Maximum downloads count.",
        value: partnerAppCampaign?.maximumDownloads,
        change: (event: React.ChangeEvent<HTMLInputElement>) =>
          setPartnerAppCampaign((campaign) => {
            if (!campaign) return campaign;
            return {
              ...campaign,
              maximumDownloads: +event.target.value,
            };
          }),
        row: true,
        min: 1,
        required: true,
      },
      {
        name: "ios",
        label: "Platform IOS:",
        helpText: "Show/hide campaign for IOS devices.",
        type: "checkbox",
        change: () =>
          setPartnerAppCampaign((campaign) => {
            if (!campaign) return campaign;

            const platform = campaign.platform ? campaign.platform : { ios: false, android: false };
            platform.ios = !platform.ios;
            return {
              ...campaign,
              platform: platform,
            };
          }),
        value: partnerAppCampaign?.platform?.ios || false,
      },
      {
        name: "android",
        label: "Platform Android:",
        helpText: "Show/hide campaign for Android devices.",
        type: "checkbox",
        change: () =>
          setPartnerAppCampaign((campaign) => {
            if (!campaign) return campaign;

            const platform = campaign.platform ? campaign.platform : { ios: false, android: false };
            platform.android = !platform.android;
            return {
              ...campaign,
              platform: platform,
            };
          }),
        value: partnerAppCampaign?.platform?.android || false,
      },

      {
        name: "appLogo",
        label: "App Logo:",
        helpText: "Campaign logo.",
        type: "image-cropper",
        imageType: ImageType.partner_app_logo,
        value: partnerAppCampaign?.appLogoUrl,
        change: (imageUrl: string) =>
          setPartnerAppCampaign((campaign) => {
            if (!campaign) return campaign;
            return {
              ...campaign,
              appLogoUrl: imageUrl,
            };
          }),
        row: true,
        custom: true,
      },
      {
        name: "appImage",
        label: "App image:",
        helpText: "Campaign image.",
        type: "image-cropper",
        imageType: ImageType.partner_app_image,
        value: partnerAppCampaign?.appImageUrl,
        change: (imageUrl: string) =>
          setPartnerAppCampaign((campaign) => {
            if (!campaign) return campaign;
            return {
              ...campaign,
              appImageUrl: imageUrl,
            };
          }),
        row: true,
        custom: true,
      },
    ],
    onSubmit: onSubmit,
    submitText: "Save",
    name: "edit-app-campaign-link",
  };

  return <CreateEditPage loading={isFetching} page={page} form={form} />;
};
