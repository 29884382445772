import React, { ReactNode } from "react";
import { useEffect, useState } from "react";
import { useQueryClient, useSuspenseQuery } from "@tanstack/react-query";
import { createFileRoute, useNavigate } from "@tanstack/react-router";
import { AxiosError } from "axios";
import { formatDate as fnsFormetDate } from "date-fns";
import { toast } from "sonner";

import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/ui/table";

import "~/pages/Users/user.css";

import { DeleteOptions, SuspiciousActivitySubModel, UserGenders, UserPointModel, UserRole } from "~/api/model";
import { useCreateTransactionV1TransactionsPost, useGetAllTransactionsV1TransactionsGet } from "~/api/transactions/transactions.gen";
import {
  getGetUserByIdV1UsersUserIdGetSuspenseQueryOptions,
  getUserPointV1UsersUserIdPointsGetSuspenseQueryOptions,
  useDeleteUserV1UsersUserIdDelete,
  useUpdateUserProfileV1UsersUserIdUpdateUserProfilePatch,
  useUpdateUserRolesV1UsersUserIdSetUserRolesPatch,
  useUpdateUserStreakDataV1UsersUserIdUpdateUserStreakDataPatch,
} from "~/api/users/users.gen";
import { getCountryOptions } from "~/helpers/country-list";
import formatDate, { convertToUsableDate } from "~/helpers/date-formatting";
import { parseFastAPIError } from "~/helpers/parse-errors";
import Button from "~/oldComponents/button/Button";
import { Checkbox } from "~/oldComponents/form/Checkbox";
import { Form } from "~/oldComponents/form/Form";
import { Field } from "~/oldComponents/form/types";
import PageLayout from "~/oldComponents/layout/PageLayout";
import { TrasnactionTable } from "~/pages/transaction";
import { useAuthSelector } from "~/providers/auth";

export const Route = createFileRoute("/_auth/users/$userId")({
  loader: ({ context: { queryClient }, params: { userId } }) => {
    queryClient.ensureQueryData(getGetUserByIdV1UsersUserIdGetSuspenseQueryOptions(userId));
    queryClient.ensureQueryData(getUserPointV1UsersUserIdPointsGetSuspenseQueryOptions(userId));
  },
  component: () => <UserPage />,
});

function UserPage() {
  const navigate = useNavigate();

  const queryClient = useQueryClient();
  const userId = Route.useParams().userId;
  const loggedInUser = useAuthSelector((context) => context?.user);

  // Transaction state
  const [enableTransactions, setEnableTransactions] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [limit, setLimit] = useState(100);
  const [transactionFilter, setTransactionFilter] = useState<{ sourcetype: string | undefined; offerid: string | undefined }>({
    sourcetype: undefined,
    offerid: undefined,
  });

  const [comments, setComments] = useState("");
  const [streak, setStreak] = useState({
    lastStreakDate: new Date(),
    longestStreak: 0,
    currentStreak: 0,
    weekStreak: 0,
  });

  const [profile, setProfile] = useState<{
    first_name: string;
    last_name: string;
    postal_code: string;
    city: string;
    street: string;
    country: string;
    profile_email: string;
    email: string;
    gender: UserGenders | undefined;
    birthdate: Date | undefined;
  }>({
    first_name: "",
    last_name: "",
    postal_code: "",
    city: "",
    street: "",
    country: "",
    profile_email: "",
    email: "",
    gender: undefined,
    birthdate: undefined,
  });

  const {
    data: { items: transactions, total, has_next: hasNext, has_prev: hasPrev } = {
      items: [],
      total: 0,
      has_next: false,
      has_prev: false,
    },
    isFetching: loadingTransactions,
    refetch: refetchTransactions,
    error: transactionErrpr,
  } = useGetAllTransactionsV1TransactionsGet(
    {
      user_id: userId,
      source_type: transactionFilter.sourcetype,
      offer_id: transactionFilter.offerid,
      page: pageNumber,
      limit: limit,
    },
    {
      query: {
        enabled: enableTransactions,
        initialData: undefined,
      },
    },
  );

  const handleSubmit = (userid: string | undefined, sourcetype: string | undefined, offerid: string | undefined) => {
    setTransactionFilter({
      sourcetype: sourcetype,
      offerid: offerid,
    });
  };

  const { data: user, isFetching, refetch } = useSuspenseQuery(getGetUserByIdV1UsersUserIdGetSuspenseQueryOptions(userId));
  const { data: userPoint }: { data: UserPointModel } = useSuspenseQuery(getUserPointV1UsersUserIdPointsGetSuspenseQueryOptions(userId));

  useEffect(() => {
    if (user) {
      setComments(user.comments || "");
      setStreak({
        lastStreakDate: convertToUsableDate(user.streak?.lastStreakDate) || new Date(),
        longestStreak: user.streak?.longestStreak || 0,
        currentStreak: user.streak?.currentStreak || 0,
        weekStreak: user.streak?.claimableStreaks?.weekStreak || 0,
      });
      setProfile({
        first_name: user.profile?.firstName || "",
        last_name: user.profile?.lastName || "",
        postal_code: user.profile?.address?.postalCode || "",
        city: user.profile?.address?.city || "",
        street: user.profile?.address?.street || "",
        country: user.profile?.address?.country || "",
        profile_email: user.profile?.email || "",
        email: user.email || "",
        gender: user.profile?.gender || undefined,
        birthdate: convertToUsableDate(user.profile?.birthdate) || undefined,
      });
    }
  }, [user]);

  const updateUserRolesMutation = useUpdateUserRolesV1UsersUserIdSetUserRolesPatch({
    mutation: {
      onSuccess: () => {
        toast("User role was successfully updated!");
        queryClient.invalidateQueries({ queryKey: ["user", userId] });
        refetch();
      },
      onError: (error: AxiosError) => {
        queryClient.invalidateQueries({ queryKey: ["user", userId] });
        toast.error("Error", { description: parseFastAPIError(error) as ReactNode });
      },
    },
  });

  const toggleCheaterRole = async () => {
    const baseRoles = user?.roles || ([] as UserRole[]);
    const roles = baseRoles.includes(UserRole.Cheater)
      ? baseRoles.filter((role: string) => role !== UserRole.Cheater)
      : [...baseRoles, UserRole.Cheater];
    await updateUserRolesMutation.mutateAsync({
      userId: userId,
      data: {
        roles: roles,
      },
    });
  };

  const togglePossibleCheaterRole = async () => {
    const baseRoles = user?.roles || ([] as UserRole[]);
    const roles = baseRoles.includes(UserRole.Possible_Cheater)
      ? baseRoles.filter((role: string) => role !== UserRole.Possible_Cheater)
      : [...baseRoles, UserRole.Possible_Cheater];
    await updateUserRolesMutation.mutateAsync({
      userId: userId,
      data: {
        roles: roles,
      },
    });
  };

  const deleteUserMutation = useDeleteUserV1UsersUserIdDelete({
    mutation: {
      onMutate: () => {
        queryClient.invalidateQueries({ queryKey: ["user", userId] });
      },
      onSuccess: () => {
        toast("User was successfully deleted!");
        navigate({
          to: "/users",
          search: {
            page: 1,
            limit: 100,
          },
        });
      },
      onError: (error: AxiosError) => {
        toast.error("Error", { description: parseFastAPIError(error) as ReactNode });
      },
    },
  });

  const onUserDelete = async (type: DeleteOptions) => {
    if (userId && userId !== loggedInUser?.user_id) {
      await deleteUserMutation.mutateAsync({ userId: userId, params: { delete_type: type } });
    } else {
      toast.error("Error", { description: "You can not delete yourself." });
    }
  };

  const updateUserProfileMutation = useUpdateUserProfileV1UsersUserIdUpdateUserProfilePatch({
    mutation: {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ["user", userId] });
        toast("User profile was successfully updated!");
        refetch();
      },
      onError: (error: AxiosError) => {
        toast.error("Error", { description: parseFastAPIError(error) as ReactNode });
      },
    },
  });

  const onProfileSubmit = async (event: React.FormEvent | undefined) => {
    event?.preventDefault();
    await updateUserProfileMutation.mutateAsync({
      userId: userId,
      data: {
        first_name: profile.first_name,
        last_name: profile.last_name,
        postal_code: profile.postal_code,
        city: profile.city,
        street: profile.street,
        country: profile.country,
        comments: comments,
        profile_email: profile.profile_email,
        email: profile.email,
        birthdate: profile.birthdate?.toISOString(),
        gender: profile.gender,
      },
    });
  };

  const updateUserStreakMutation = useUpdateUserStreakDataV1UsersUserIdUpdateUserStreakDataPatch({
    mutation: {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ["user", userId] });
        toast("User streak was successfully updated!");
      },
      onError: (error: AxiosError) => {
        queryClient.invalidateQueries({ queryKey: ["user", userId] });
        toast.error("Error", { description: parseFastAPIError(error) as ReactNode });
      },
    },
  });

  const onStreakSubmit = async (event: React.FormEvent | undefined) => {
    event?.preventDefault();
    updateUserStreakMutation.mutateAsync({
      userId: userId,
      data: {
        last_streak_date: streak.lastStreakDate.toISOString(),
        longest_streak: streak.longestStreak,
        current_streak: streak.currentStreak,
        week_streak: streak.weekStreak,
      },
    });
  };

  const createTransactionMutation = useCreateTransactionV1TransactionsPost({
    mutation: {
      onSuccess: () => {
        toast("Admin points transaction successfully created!");
        setTimeout(() => {
          refetch();
          if (enableTransactions) {
            refetchTransactions();
          }
        }, 1000);
      },
      onError: (error: AxiosError) => {
        toast.error("Error", { description: parseFastAPIError(error) as ReactNode });
      },
    },
  });

  const onTransactionSubmit = async (event: React.FormEvent) => {
    event?.preventDefault();

    const form = event.target as HTMLFormElement;
    const formData = new FormData(form);
    const pointsAmount = formData.get("pointsAmount");
    const numberPointsAmount = Number(pointsAmount);
    if (numberPointsAmount === 0 || isNaN(numberPointsAmount)) {
      return toast("Giving 0 points does not make sense.");
    }

    createTransactionMutation.mutateAsync({
      data: {
        amount: Number(pointsAmount),
        user_id: userId as string,
      },
    });

    form.querySelector("[name='pointsAmount'")?.setAttribute("value", "0");
  };

  const streakFields: Field[] = [
    {
      name: "lastStreakDate",
      label: "Last Streak Date",
      helpText: "Last date that the streak was synced. Needs to be set to today, if you change the streak",
      type: "date",
      change: (e: React.ChangeEvent<HTMLInputElement>) => {
        setStreak((streak) => {
          streak.lastStreakDate = convertToUsableDate(e.target.value) || new Date();
          return streak;
        });
      },
      value: fnsFormetDate(streak.lastStreakDate, "yyyy-MM-dd"),
      custom: true,
    },
    {
      name: "longestStreak",
      label: "Longest Streak",
      helpText: "The longest streak the user had",
      type: "number",
      change: (event: React.ChangeEvent<HTMLInputElement>) => {
        event.preventDefault();
        setStreak((streak) => {
          streak.longestStreak = +event.target.value;
          return streak;
        });
      },
      value: streak.longestStreak,
      min: 0,
    },
    {
      name: "currentStreak",
      label: "Current Streak",
      helpText: "The current streak of the user",
      type: "number",
      change: (event: React.ChangeEvent<HTMLInputElement>) => {
        event.preventDefault();
        setStreak((streak) => {
          streak.currentStreak = +event.target.value;
          return streak;
        });
      },
      value: streak.currentStreak,
      min: 0,
    },
    {
      name: "weekStreak",
      label: "7 day streak",
      helpText: "The amount of days claimed in a row to be able to claim the 7 day streak",
      type: "number",
      change: (event: React.ChangeEvent<HTMLInputElement>) => {
        event.preventDefault();
        setStreak((streak) => {
          streak.weekStreak = +event.target.value;
          return streak;
        });
      },
      value: streak.weekStreak,
      min: 0,
      max: 7,
    },
  ];

  const profileFields: Field[] = [
    {
      name: "firstName",
      label: "First name:",
      helpText: "User first name.",
      type: "text",
      change: (e: React.ChangeEvent<HTMLInputElement>) => {
        setProfile((profile) => {
          profile.first_name = e.target.value;
          return profile;
        });
      },
      value: profile.first_name,
    },
    {
      name: "lastName",
      label: "Last name:",
      helpText: "User last name.",
      type: "text",
      change: (e: React.ChangeEvent<HTMLInputElement>) => {
        setProfile((profile) => {
          profile.last_name = e.target.value;
          return profile;
        });
      },
      value: profile.last_name,
    },
    {
      name: "verifiedEmail",
      label: "Verified Email:",
      helpText: "The email that the user has verified.",
      type: "text",
      change: (e: React.ChangeEvent<HTMLInputElement>) => {
        const email = e.target.value;
        setProfile((profile) => {
          profile.email = e.target.value;
          if (email) {
            profile.profile_email = "";
          }
          return profile;
        });
      },
      value: user.email,
    },
    {
      name: "profileEmail",
      label: "Profile email:",
      helpText: "The old email field that is deprecated.",
      type: "text",
      change: (e: React.ChangeEvent<HTMLInputElement>) => {
        setProfile((profile) => {
          profile.profile_email = e.target.value;
          return profile;
        });
      },
      value: profile.profile_email,
    },
    {
      name: "birthdate",
      label: "Birhtdate:",
      helpText: "The day the user was born.",
      type: "date",
      change: (e: React.ChangeEvent<HTMLInputElement>) => {
        setProfile((profile) => {
          profile.birthdate = new Date(e.target.value);
          return profile;
        });
      },
      value: profile.birthdate?.toISOString().split("T")[0],
      required: false,
      empty: " ",
    },
    {
      name: "gender",
      label: "Gender:",
      helpText: "The gender of the user.",
      type: "search-select",
      change: (data: string) => {
        setProfile((profile) => {
          profile.gender = data as UserGenders;
          return profile;
        });
      },
      value: profile.gender,
      options: [
        {
          name: "Female",
          value: UserGenders.Female,
        },
        {
          name: "Male",
          value: UserGenders.Male,
        },
        {
          name: "Other",
          value: UserGenders.Other,
        },
        {
          name: "Prefer not to say",
          value: UserGenders.PreferNotToSay,
        },
      ],
      required: true,
      empty: " ",
    },
    {
      name: "country",
      label: "Country:",
      helpText: "User country.",
      type: "search-select",
      change: (data: string) => {
        setProfile((profile) => {
          profile.country = data;
          return profile;
        });
      },
      value: profile.country,
      options: getCountryOptions(true),
      required: true,
      empty: " ",
    },
    {
      name: "city",
      label: "City:",
      helpText: "User city.",
      type: "text",
      change: (e: React.ChangeEvent<HTMLInputElement>) => {
        setProfile((profile) => {
          profile.city = e.target.value;
          return profile;
        });
      },
      value: profile.city,
    },
    {
      name: "street",
      label: "Street:",
      helpText: "User street.",
      type: "text",
      change: (e: React.ChangeEvent<HTMLInputElement>) => {
        setProfile((profile) => {
          profile.street = e.target.value;
          return profile;
        });
      },
      value: profile.street,
    },
    {
      name: "postalCode",
      label: "Postal code:",
      helpText: "User postal code.",
      type: "text",
      change: (e: React.ChangeEvent<HTMLInputElement>) => {
        setProfile((profile) => {
          profile.postal_code = e.target.value;
          return profile;
        });
      },
      value: profile.postal_code,
    },
    {
      name: "comments",
      label: "Comments:",
      helpText: "Admin user comments about the user.",
      type: "text-editor",
      row: true,
      change: (data: string) => {
        setComments(data);
      },
      value: comments,
      custom: true,
    },
  ];

  const transactionFields: Field[] = [
    {
      name: "pointsAmount",
      label: "Points amount",
      helpText: "Provide user certain amount of points.",
      type: "number",
    },
  ];

  const userPersonalData = [
    { label: "User ID:", field: user._id },
    { label: "Status:", field: user.status },
    { label: "Created at:", field: user.createdAt ? new Date(user.createdAt).toDateString() : "-" },
    { label: "Last updated:", field: user.updatedAt ? new Date(user.updatedAt).toDateString() : "-" },
    { label: "Wallet balance:", field: user.walletBalance ? Math.round(user.walletBalance) : "-" },
    { label: "Phone:", field: user.phonenumber || "-" },
    { label: "Apple ID:", field: user.appleId || "-" },
    { label: "Facebook ID:", field: user.fbId || "-" },
    { label: "Google ID:", field: user.googleId || "-" },
    { label: "Last used platform:", fields: user.platform || "-" },
    { label: "language:", fields: user.language || "-" },
    { label: "Total steps:", fields: user.totalSteps },
    { label: "Spent points:", fields: user.spentPoints },
    { label: "Is Premium:", fields: user.isPremium ? "Yes" : "No" },
  ];

  const additionalActions = () => {
    return (
      <Button onClick={() => onUserDelete(DeleteOptions.Hard)} disabled={isFetching} styleType="danger">
        Hard delete
      </Button>
    );
  };

  return (
    <PageLayout
      loading={isFetching}
      title="Stepler user"
      description="Use this elements, if you want to show some hints or additional information"
      onDeleteClick={() => onUserDelete(DeleteOptions.Soft)}
      onDeleteText="Soft delete"
      additionalActions={additionalActions()}
    >
      <section>
        <section className="user-data_section">
          <h5 className="data_section-header">Personal data</h5>
          <Table>
            <TableBody>
              {userPersonalData.map((data) => (
                <TableRow key={data.label}>
                  <TableCell className="w-[300px] font-bold">{data.label}</TableCell>
                  <TableCell>{data.field}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <Form name="user-profile" fields={profileFields} onSubmit={onProfileSubmit} submitText="Update profile" />
        </section>
        <section className="user-data_section">
          <h5 className="data_section-header">Suspicious activity:</h5>
          <div className="user-data_checkboxes">
            <div className="user-data_checkbox-item">
              <span className="user-data_address-form_label">Cheater:</span>
              <Checkbox name="ischeater" checked={user.roles?.includes(UserRole.Cheater)} onChange={toggleCheaterRole} />
            </div>
            <div className="user-data_checkbox-item">
              <span className="user-data_address-form_label">Possible cheater:</span>
              <Checkbox name="ispossiblecheater" checked={user?.roles?.includes(UserRole.Possible_Cheater)} onChange={togglePossibleCheaterRole} />
            </div>
          </div>
          {!!user?.suspiciousActivity?.length && (
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead className="w-[300px]">Check date</TableHead>
                  <TableHead>What happened</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {user?.suspiciousActivity.map((activity: SuspiciousActivitySubModel) => (
                  <TableRow key={activity.date}>
                    <TableCell className="font-bold">{formatDate(activity.date || "") as ReactNode}</TableCell>
                    <TableCell>{activity.description}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          )}
          {user?.suspiciousActivity?.length == 0 && <small>No suspicious activity reported</small>}
        </section>
        <section className="user-data_section">
          <h5 className="data_section-header">Streaks</h5>
          <Form vertical={true} name="user-streak" fields={streakFields} onSubmit={onStreakSubmit} submitText="Update streak" />
        </section>
        <section className="user-data_section">
          <h5 className="data_section-header">Create admin transaction</h5>
          <Form vertical={true} name="admin-points" fields={transactionFields} onSubmit={onTransactionSubmit} submitText="Create transaction" />
        </section>

        {userPoint && Object.keys(userPoint.points).length > 0 && (
          <section className="user-data_section">
            <h3 className="data_section-header">User total points</h3>

            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead className="w-[300px]">Type</TableHead>
                  <TableHead>Points</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {Object.entries(userPoint?.points)
                  .sort(([typeA], [typeB]) => typeA.localeCompare(typeB))
                  .map(([type, points]: [string, number]) => (
                    <TableRow key={type}>
                      <TableCell className="font-bold">{type}</TableCell>
                      <TableCell>{points}</TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </section>
        )}

        <section>
          {enableTransactions ? (
            <TrasnactionTable
              pageNumber={pageNumber}
              limit={limit}
              initUserId={userId}
              handleSubmit={handleSubmit}
              setPageNumber={setPageNumber}
              setLimit={setLimit}
              items={transactions}
              hasNext={hasNext}
              hasPrev={hasPrev}
              total={total}
              loading={loadingTransactions}
              error={transactionErrpr}
            />
          ) : (
            <Button onClick={() => setEnableTransactions(true)}>Show transactions</Button>
          )}
        </section>
      </section>
    </PageLayout>
  );
}
