import React, { useEffect } from "react";
import { useMemo, useState } from "react";
import { Link, UseNavigateResult } from "@tanstack/react-router";
import { ColumnDef, getCoreRowModel, useReactTable } from "@tanstack/react-table";

import { DataTable } from "@/datatable";
import { Icon } from "@/icon/icon";
import { buttonVariants } from "@/ui/button";

import { UserSearch } from "./types";

import { UserModelOutput } from "~/api/model";
import { useSearchUsersV1UsersGet } from "~/api/users/users.gen";
import { getCountryName } from "~/helpers/country-list";

type UserTableProps = {
  searchParams: UserSearch;
  navigate: UseNavigateResult<"/users">;
};

export function UserTable({ searchParams, navigate }: UserTableProps) {
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 100,
  });

  useEffect(() => {
    if (pagination.pageIndex + 1 != searchParams.page || pagination.pageSize != searchParams.limit) {
      navigate({
        search: { ...searchParams, page: pagination.pageIndex + 1, limit: pagination.pageSize },
        replace: true,
      });
    }
  }, [pagination]);

  const { data, isLoading, isFetching } = useSearchUsersV1UsersGet(
    {
      user_id: searchParams.user_id,
      email: searchParams.email,
      first_name: searchParams.first_name,
      last_name: searchParams.last_name,
      phone_number: searchParams.phonenumber,
      postal_code: searchParams.postal_code,
      street: searchParams.street,
      city: searchParams.city,
      country: searchParams.country,
      is_cheater: searchParams.is_cheater,
      is_possible_cheater: searchParams.is_possible_cheater,
      status: searchParams.status,
      page: searchParams.page,
      limit: searchParams.limit,
    },
    {
      query: {
        initialData: {
          total: 0,
          items: [],
          has_next: false,
          has_prev: false,
        },
      },
    },
  );

  const columns = useMemo<ColumnDef<UserModelOutput>[]>(
    () => [
      {
        accessorKey: "actions",
        header: () => "",
        cell: ({ row }) => (
          <Link className={buttonVariants({ variant: "default" })} to="/users/$userId" params={{ userId: row.original._id as string }}>
            <Icon icon="Search" />
          </Link>
        ),
      },
      {
        accessorKey: "_id",
        header: () => "Id",
      },
      {
        accessorKey: "status",
        header: () => "Status",
      },
      {
        accessorKey: "first_name",
        header: () => "First Name",
        cell: ({ row }) => row.original.profile?.firstName || "-",
      },
      {
        accessorKey: "last_name",
        header: () => "Last Name",
        cell: ({ row }) => row.original.profile?.lastName || "-",
      },
      {
        accessorKey: "email",
        header: () => "Email",
      },
      {
        accessorKey: "address",
        header: () => "Address",
        cell: ({ row }) => (
          <div>
            {row.original.profile?.address?.street || "-"}
            <hr />
            {row.original.profile?.address?.city} - {row.original.profile?.address?.postalCode}
            <hr />
            {getCountryName(row.original.profile?.address?.country) || "-"}
          </div>
        ),
      },
      {
        accessorKey: "phonenumber",
        header: () => "Phonenumber",
      },
      {
        accessorKey: "walletBalance",
        header: () => "Wallet Balance",
      },
      {
        accessorKey: "is_cheater",
        header: () => "Is Cheater",
        cell: ({ row }) => (row.original.roles?.includes("Cheater") ? "Yes" : "No"),
      },
      {
        accessorKey: "is_possible_cheater",
        header: () => "Is Possible Cheater",
        cell: ({ row }) => (row.original.roles?.includes("Cheater") ? "Yes" : "No"),
      },
    ],
    [],
  );

  const table = useReactTable({
    data: data.items,
    columns: columns,
    getCoreRowModel: getCoreRowModel(),
    manualPagination: true,
    rowCount: data.total,
    onPaginationChange: setPagination,
    state: {
      pagination: pagination,
    },
  });

  return (
    <DataTable
      table={table}
      columns={columns}
      emptyState={isLoading || isFetching ? "Searching...." : "No results found. Please ajust the filters"}
    />
  );
}
