import { IconName } from "@/icon/types";

 
type CategoryMap = {
  name: string;
  icon: IconName;
};

const categoryMapping: Record<string, CategoryMap> = {
  beauty: {
    name: "Beauty",
    icon: "CategoryBeauty",
  },
  digital_services: {
    name: "Digital Services",
    icon: "CategoryDigitalServices",
  },
  economy: {
    name: "Economy",
    icon: "CategoryEconomy",
  },
  experiences: {
    name: "Experiences",
    icon: "CategoryExperiences",
  },
  family_and_kids: {
    name: "Family & Kids",
    icon: "CategoryFamilyKids",
  },
  fashion_and_accessories: {
    name: "Fashion & Accessories",
    icon: "CategoryFashionAccessories",
  },
  food_and_nutrition: {
    name: "Food & Nutrition",
    icon: "CategoryFoodNutrition",
  },
  health_and_fitness: {
    name: "Health & Fitness",
    icon: "CategoryHealthFitness",
  },
  home_and_interior: {
    name: "Home & Interior",
    icon: "CategoryHomeInterior",
  },
  media_and_streaming: {
    name: "Media & Streaming",
    icon: "CategoryMediaStreaming",
  },
  pets: {
    name: "Pets",
    icon: "CategoryPets",
  },
  sports_and_outdoors: {
    name: "Sports & Outdoors",
    icon: "CategorySportsOutdoor",
  },
  tech_and_gaming: {
    name: "Tech & Gaming",
    icon: "CategoryTechGaming",
  },
  travel: {
    name: "Travel",
    icon: "CategoryTravel",
  },
};

export const getCategoryName = (category: string) => {
  if (!categoryMapping[category]) {
     
    console.error(category);
  } else {
    return categoryMapping[category].name;
  }
};

export const getCategoryIcon = (category: string) => {
  if (!categoryMapping[category]) {
     
    console.error(category);
    return "Add";
  } else {
    return categoryMapping[category].icon;
  }
};
