/**
 * Generated by orval
 * Do not edit manually.
 * Stepler API for the Admin
 * This is the documentation for the Stepler Admin.
 * OpenAPI spec version: 1.0.0
 */
import {
  useMutation,
  useQuery,
  useSuspenseQuery
} from '@tanstack/react-query'
import type {
  DefinedInitialDataOptions,
  DefinedUseQueryResult,
  MutationFunction,
  QueryFunction,
  QueryKey,
  UndefinedInitialDataOptions,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
  UseSuspenseQueryOptions,
  UseSuspenseQueryResult
} from '@tanstack/react-query'
import type {
  Error400Response,
  HTTPValidationError,
  InviteLinkModel,
  InviteLinkWithInstalls,
  UpdateInviteLinkInput
} from '.././model'
import { axiosInstance } from '.././base';
import type { ErrorType, BodyType } from '.././base';


type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];


/**
 * Get all invite links
 * @summary Get Invite Links
 */
export const getInviteLinksV1InviteLinksGet = (
    
 options?: SecondParameter<typeof axiosInstance>,signal?: AbortSignal
) => {
      
      
      return axiosInstance<InviteLinkWithInstalls[]>(
      {url: `/v1/invite_links/`, method: 'GET', signal
    },
      options);
    }
  

export const getGetInviteLinksV1InviteLinksGetQueryKey = () => {
    return [`/v1/invite_links/`] as const;
    }

    
export const getGetInviteLinksV1InviteLinksGetQueryOptions = <TData = Awaited<ReturnType<typeof getInviteLinksV1InviteLinksGet>>, TError = ErrorType<unknown>>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getInviteLinksV1InviteLinksGet>>, TError, TData>>, request?: SecondParameter<typeof axiosInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetInviteLinksV1InviteLinksGetQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getInviteLinksV1InviteLinksGet>>> = ({ signal }) => getInviteLinksV1InviteLinksGet(requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getInviteLinksV1InviteLinksGet>>, TError, TData> & { queryKey: QueryKey }
}

export type GetInviteLinksV1InviteLinksGetQueryResult = NonNullable<Awaited<ReturnType<typeof getInviteLinksV1InviteLinksGet>>>
export type GetInviteLinksV1InviteLinksGetQueryError = ErrorType<unknown>


export function useGetInviteLinksV1InviteLinksGet<TData = Awaited<ReturnType<typeof getInviteLinksV1InviteLinksGet>>, TError = ErrorType<unknown>>(
  options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getInviteLinksV1InviteLinksGet>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getInviteLinksV1InviteLinksGet>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof axiosInstance>}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetInviteLinksV1InviteLinksGet<TData = Awaited<ReturnType<typeof getInviteLinksV1InviteLinksGet>>, TError = ErrorType<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getInviteLinksV1InviteLinksGet>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getInviteLinksV1InviteLinksGet>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof axiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetInviteLinksV1InviteLinksGet<TData = Awaited<ReturnType<typeof getInviteLinksV1InviteLinksGet>>, TError = ErrorType<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getInviteLinksV1InviteLinksGet>>, TError, TData>>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
/**
 * @summary Get Invite Links
 */

export function useGetInviteLinksV1InviteLinksGet<TData = Awaited<ReturnType<typeof getInviteLinksV1InviteLinksGet>>, TError = ErrorType<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getInviteLinksV1InviteLinksGet>>, TError, TData>>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetInviteLinksV1InviteLinksGetQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetInviteLinksV1InviteLinksGetSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof getInviteLinksV1InviteLinksGet>>, TError = ErrorType<unknown>>( options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getInviteLinksV1InviteLinksGet>>, TError, TData>>, request?: SecondParameter<typeof axiosInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetInviteLinksV1InviteLinksGetQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getInviteLinksV1InviteLinksGet>>> = ({ signal }) => getInviteLinksV1InviteLinksGet(requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof getInviteLinksV1InviteLinksGet>>, TError, TData> & { queryKey: QueryKey }
}

export type GetInviteLinksV1InviteLinksGetSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof getInviteLinksV1InviteLinksGet>>>
export type GetInviteLinksV1InviteLinksGetSuspenseQueryError = ErrorType<unknown>


export function useGetInviteLinksV1InviteLinksGetSuspense<TData = Awaited<ReturnType<typeof getInviteLinksV1InviteLinksGet>>, TError = ErrorType<unknown>>(
  options: { query:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getInviteLinksV1InviteLinksGet>>, TError, TData>>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetInviteLinksV1InviteLinksGetSuspense<TData = Awaited<ReturnType<typeof getInviteLinksV1InviteLinksGet>>, TError = ErrorType<unknown>>(
  options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getInviteLinksV1InviteLinksGet>>, TError, TData>>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetInviteLinksV1InviteLinksGetSuspense<TData = Awaited<ReturnType<typeof getInviteLinksV1InviteLinksGet>>, TError = ErrorType<unknown>>(
  options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getInviteLinksV1InviteLinksGet>>, TError, TData>>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
/**
 * @summary Get Invite Links
 */

export function useGetInviteLinksV1InviteLinksGetSuspense<TData = Awaited<ReturnType<typeof getInviteLinksV1InviteLinksGet>>, TError = ErrorType<unknown>>(
  options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getInviteLinksV1InviteLinksGet>>, TError, TData>>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetInviteLinksV1InviteLinksGetSuspenseQueryOptions(options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * Create invite link
 * @summary Create Invite Link
 */
export const createInviteLinkV1InviteLinksPost = (
    inviteLinkModel: BodyType<InviteLinkModel>,
 options?: SecondParameter<typeof axiosInstance>,) => {
      
      
      return axiosInstance<InviteLinkModel>(
      {url: `/v1/invite_links/`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: inviteLinkModel
    },
      options);
    }
  


export const getCreateInviteLinkV1InviteLinksPostMutationOptions = <TError = ErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createInviteLinkV1InviteLinksPost>>, TError,{data: BodyType<InviteLinkModel>}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof createInviteLinkV1InviteLinksPost>>, TError,{data: BodyType<InviteLinkModel>}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createInviteLinkV1InviteLinksPost>>, {data: BodyType<InviteLinkModel>}> = (props) => {
          const {data} = props ?? {};

          return  createInviteLinkV1InviteLinksPost(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type CreateInviteLinkV1InviteLinksPostMutationResult = NonNullable<Awaited<ReturnType<typeof createInviteLinkV1InviteLinksPost>>>
    export type CreateInviteLinkV1InviteLinksPostMutationBody = BodyType<InviteLinkModel>
    export type CreateInviteLinkV1InviteLinksPostMutationError = ErrorType<HTTPValidationError>

    /**
 * @summary Create Invite Link
 */
export const useCreateInviteLinkV1InviteLinksPost = <TError = ErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createInviteLinkV1InviteLinksPost>>, TError,{data: BodyType<InviteLinkModel>}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof createInviteLinkV1InviteLinksPost>>,
        TError,
        {data: BodyType<InviteLinkModel>},
        TContext
      > => {

      const mutationOptions = getCreateInviteLinkV1InviteLinksPostMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Get invite link by id
 * @summary Get Invite Link By Id
 */
export const getInviteLinkByIdV1InviteLinksInviteLinkIdGet = (
    inviteLinkId: string,
 options?: SecondParameter<typeof axiosInstance>,signal?: AbortSignal
) => {
      
      
      return axiosInstance<InviteLinkModel>(
      {url: `/v1/invite_links/${inviteLinkId}/`, method: 'GET', signal
    },
      options);
    }
  

export const getGetInviteLinkByIdV1InviteLinksInviteLinkIdGetQueryKey = (inviteLinkId: string,) => {
    return [`/v1/invite_links/${inviteLinkId}/`] as const;
    }

    
export const getGetInviteLinkByIdV1InviteLinksInviteLinkIdGetQueryOptions = <TData = Awaited<ReturnType<typeof getInviteLinkByIdV1InviteLinksInviteLinkIdGet>>, TError = ErrorType<HTTPValidationError>>(inviteLinkId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getInviteLinkByIdV1InviteLinksInviteLinkIdGet>>, TError, TData>>, request?: SecondParameter<typeof axiosInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetInviteLinkByIdV1InviteLinksInviteLinkIdGetQueryKey(inviteLinkId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getInviteLinkByIdV1InviteLinksInviteLinkIdGet>>> = ({ signal }) => getInviteLinkByIdV1InviteLinksInviteLinkIdGet(inviteLinkId, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(inviteLinkId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getInviteLinkByIdV1InviteLinksInviteLinkIdGet>>, TError, TData> & { queryKey: QueryKey }
}

export type GetInviteLinkByIdV1InviteLinksInviteLinkIdGetQueryResult = NonNullable<Awaited<ReturnType<typeof getInviteLinkByIdV1InviteLinksInviteLinkIdGet>>>
export type GetInviteLinkByIdV1InviteLinksInviteLinkIdGetQueryError = ErrorType<HTTPValidationError>


export function useGetInviteLinkByIdV1InviteLinksInviteLinkIdGet<TData = Awaited<ReturnType<typeof getInviteLinkByIdV1InviteLinksInviteLinkIdGet>>, TError = ErrorType<HTTPValidationError>>(
 inviteLinkId: string, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getInviteLinkByIdV1InviteLinksInviteLinkIdGet>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getInviteLinkByIdV1InviteLinksInviteLinkIdGet>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof axiosInstance>}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetInviteLinkByIdV1InviteLinksInviteLinkIdGet<TData = Awaited<ReturnType<typeof getInviteLinkByIdV1InviteLinksInviteLinkIdGet>>, TError = ErrorType<HTTPValidationError>>(
 inviteLinkId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getInviteLinkByIdV1InviteLinksInviteLinkIdGet>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getInviteLinkByIdV1InviteLinksInviteLinkIdGet>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof axiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetInviteLinkByIdV1InviteLinksInviteLinkIdGet<TData = Awaited<ReturnType<typeof getInviteLinkByIdV1InviteLinksInviteLinkIdGet>>, TError = ErrorType<HTTPValidationError>>(
 inviteLinkId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getInviteLinkByIdV1InviteLinksInviteLinkIdGet>>, TError, TData>>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
/**
 * @summary Get Invite Link By Id
 */

export function useGetInviteLinkByIdV1InviteLinksInviteLinkIdGet<TData = Awaited<ReturnType<typeof getInviteLinkByIdV1InviteLinksInviteLinkIdGet>>, TError = ErrorType<HTTPValidationError>>(
 inviteLinkId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getInviteLinkByIdV1InviteLinksInviteLinkIdGet>>, TError, TData>>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetInviteLinkByIdV1InviteLinksInviteLinkIdGetQueryOptions(inviteLinkId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetInviteLinkByIdV1InviteLinksInviteLinkIdGetSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof getInviteLinkByIdV1InviteLinksInviteLinkIdGet>>, TError = ErrorType<HTTPValidationError>>(inviteLinkId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getInviteLinkByIdV1InviteLinksInviteLinkIdGet>>, TError, TData>>, request?: SecondParameter<typeof axiosInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetInviteLinkByIdV1InviteLinksInviteLinkIdGetQueryKey(inviteLinkId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getInviteLinkByIdV1InviteLinksInviteLinkIdGet>>> = ({ signal }) => getInviteLinkByIdV1InviteLinksInviteLinkIdGet(inviteLinkId, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(inviteLinkId), ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof getInviteLinkByIdV1InviteLinksInviteLinkIdGet>>, TError, TData> & { queryKey: QueryKey }
}

export type GetInviteLinkByIdV1InviteLinksInviteLinkIdGetSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof getInviteLinkByIdV1InviteLinksInviteLinkIdGet>>>
export type GetInviteLinkByIdV1InviteLinksInviteLinkIdGetSuspenseQueryError = ErrorType<HTTPValidationError>


export function useGetInviteLinkByIdV1InviteLinksInviteLinkIdGetSuspense<TData = Awaited<ReturnType<typeof getInviteLinkByIdV1InviteLinksInviteLinkIdGet>>, TError = ErrorType<HTTPValidationError>>(
 inviteLinkId: string, options: { query:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getInviteLinkByIdV1InviteLinksInviteLinkIdGet>>, TError, TData>>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetInviteLinkByIdV1InviteLinksInviteLinkIdGetSuspense<TData = Awaited<ReturnType<typeof getInviteLinkByIdV1InviteLinksInviteLinkIdGet>>, TError = ErrorType<HTTPValidationError>>(
 inviteLinkId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getInviteLinkByIdV1InviteLinksInviteLinkIdGet>>, TError, TData>>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetInviteLinkByIdV1InviteLinksInviteLinkIdGetSuspense<TData = Awaited<ReturnType<typeof getInviteLinkByIdV1InviteLinksInviteLinkIdGet>>, TError = ErrorType<HTTPValidationError>>(
 inviteLinkId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getInviteLinkByIdV1InviteLinksInviteLinkIdGet>>, TError, TData>>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
/**
 * @summary Get Invite Link By Id
 */

export function useGetInviteLinkByIdV1InviteLinksInviteLinkIdGetSuspense<TData = Awaited<ReturnType<typeof getInviteLinkByIdV1InviteLinksInviteLinkIdGet>>, TError = ErrorType<HTTPValidationError>>(
 inviteLinkId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getInviteLinkByIdV1InviteLinksInviteLinkIdGet>>, TError, TData>>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetInviteLinkByIdV1InviteLinksInviteLinkIdGetSuspenseQueryOptions(inviteLinkId,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * Update invite link
 * @summary Update Invite Link
 */
export const updateInviteLinkV1InviteLinksInviteLinkIdPatch = (
    inviteLinkId: string,
    updateInviteLinkInput: BodyType<UpdateInviteLinkInput>,
 options?: SecondParameter<typeof axiosInstance>,) => {
      
      
      return axiosInstance<InviteLinkModel>(
      {url: `/v1/invite_links/${inviteLinkId}/`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: updateInviteLinkInput
    },
      options);
    }
  


export const getUpdateInviteLinkV1InviteLinksInviteLinkIdPatchMutationOptions = <TError = ErrorType<Error400Response | HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateInviteLinkV1InviteLinksInviteLinkIdPatch>>, TError,{inviteLinkId: string;data: BodyType<UpdateInviteLinkInput>}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof updateInviteLinkV1InviteLinksInviteLinkIdPatch>>, TError,{inviteLinkId: string;data: BodyType<UpdateInviteLinkInput>}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateInviteLinkV1InviteLinksInviteLinkIdPatch>>, {inviteLinkId: string;data: BodyType<UpdateInviteLinkInput>}> = (props) => {
          const {inviteLinkId,data} = props ?? {};

          return  updateInviteLinkV1InviteLinksInviteLinkIdPatch(inviteLinkId,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type UpdateInviteLinkV1InviteLinksInviteLinkIdPatchMutationResult = NonNullable<Awaited<ReturnType<typeof updateInviteLinkV1InviteLinksInviteLinkIdPatch>>>
    export type UpdateInviteLinkV1InviteLinksInviteLinkIdPatchMutationBody = BodyType<UpdateInviteLinkInput>
    export type UpdateInviteLinkV1InviteLinksInviteLinkIdPatchMutationError = ErrorType<Error400Response | HTTPValidationError>

    /**
 * @summary Update Invite Link
 */
export const useUpdateInviteLinkV1InviteLinksInviteLinkIdPatch = <TError = ErrorType<Error400Response | HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateInviteLinkV1InviteLinksInviteLinkIdPatch>>, TError,{inviteLinkId: string;data: BodyType<UpdateInviteLinkInput>}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof updateInviteLinkV1InviteLinksInviteLinkIdPatch>>,
        TError,
        {inviteLinkId: string;data: BodyType<UpdateInviteLinkInput>},
        TContext
      > => {

      const mutationOptions = getUpdateInviteLinkV1InviteLinksInviteLinkIdPatchMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Delete invite link
 * @summary Delete Invite Link
 */
export const deleteInviteLinkV1InviteLinksInviteLinkIdDelete = (
    inviteLinkId: string,
 options?: SecondParameter<typeof axiosInstance>,) => {
      
      
      return axiosInstance<void>(
      {url: `/v1/invite_links/${inviteLinkId}/`, method: 'DELETE'
    },
      options);
    }
  


export const getDeleteInviteLinkV1InviteLinksInviteLinkIdDeleteMutationOptions = <TError = ErrorType<Error400Response | HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteInviteLinkV1InviteLinksInviteLinkIdDelete>>, TError,{inviteLinkId: string}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof deleteInviteLinkV1InviteLinksInviteLinkIdDelete>>, TError,{inviteLinkId: string}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteInviteLinkV1InviteLinksInviteLinkIdDelete>>, {inviteLinkId: string}> = (props) => {
          const {inviteLinkId} = props ?? {};

          return  deleteInviteLinkV1InviteLinksInviteLinkIdDelete(inviteLinkId,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteInviteLinkV1InviteLinksInviteLinkIdDeleteMutationResult = NonNullable<Awaited<ReturnType<typeof deleteInviteLinkV1InviteLinksInviteLinkIdDelete>>>
    
    export type DeleteInviteLinkV1InviteLinksInviteLinkIdDeleteMutationError = ErrorType<Error400Response | HTTPValidationError>

    /**
 * @summary Delete Invite Link
 */
export const useDeleteInviteLinkV1InviteLinksInviteLinkIdDelete = <TError = ErrorType<Error400Response | HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteInviteLinkV1InviteLinksInviteLinkIdDelete>>, TError,{inviteLinkId: string}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof deleteInviteLinkV1InviteLinksInviteLinkIdDelete>>,
        TError,
        {inviteLinkId: string},
        TContext
      > => {

      const mutationOptions = getDeleteInviteLinkV1InviteLinksInviteLinkIdDeleteMutationOptions(options);

      return useMutation(mutationOptions);
    }
    