import { IconName } from "@/icon/types";

export interface Route {
  to: string;
  name: string;
  role: "Admin" | "Admin_Owner" | "ANY";
  nested?: Route[];
  target?: string;
  icon: IconName;
  params?: { [key: string]: string };
}

export interface CustomRoute {
  onClick?: () => void;
  name: string;
  role: "Admin" | "Admin_Owner" | "ANY";
  icon: IconName;
}

export const routes: Route[] = [
  {
    to: "/",
    icon: "Dashboard",
    role: "ANY",
    name: "Dashboard",
  },
  {
    to: "/PLACE_MARKET/marketplace",
    icon: "MarketPlace",
    role: "Admin",
    name: "Marketplace",
  },
  {
    to: "/users",
    icon: "Users",
    role: "Admin",
    name: "Users",
  },
  {
    to: "/transactions",
    icon: "Transactions",
    role: "Admin",
    name: "Transactions",
  },
  {
    to: "/",
    icon: "Link",
    role: "Admin",
    name: "Links",
    nested: [
      {
        to: "/points-links",
        icon: "PointsLinks",
        role: "Admin",
        name: "Points Link",
      },
      {
        to: "/invite-links",
        icon: "InviteLinks",
        role: "Admin",
        name: "Invite Links",
      },
    ],
  },
  {
    to: "/",
    icon: "Campaigns",
    role: "Admin",
    name: "Campaigns",
    nested: [
      {
        to: "/invite-campaigns",
        icon: "InviteCampaign",
        role: "Admin",
        name: "Invite campaigns",
      },
      {
        to: "/step-campaigns",
        icon: "StepCampaign",
        role: "Admin",
        name: "Step campaigns",
      },
      {
        to: "/partner-apps",
        icon: "PartnerApps",
        role: "Admin",
        name: "Partner apps",
      },
    ],
  },
  {
    to: "/tickets",
    icon: "CustomerSupport",
    role: "Admin",
    name: "Customer support",
  },
  {
    to: "/",
    icon: "Settings",
    role: "Admin_Owner",
    name: "Admin config",
    nested: [
      {
        to: "/general-config",
        icon: "FaGlobe",
        role: "Admin_Owner",
        name: "General config",
      },
      {
        to: "/markets",
        icon: "FaEarthEurope",
        role: "Admin_Owner",
        name: "Markets",
      },
      {
        to: "/users/admin",
        icon: "FaUserTie",
        role: "Admin_Owner",
        name: "Permissions",
      },
    ],
  },
];

export const externalRoutes: Route[] = [
  {
    to: "https://analytics.stepler.io/",
    icon: "Analytics",
    role: "ANY",
    name: "Analytics",
    target: "_blank",
  },
  {
    to: "https://analytics.stepler.io/dashboard/14-order-analytics?tab=262-information&date_filter=past1months&country=SE",
    icon: "BarChart",
    role: "ANY",
    name: "Order Analytics",
    target: "_blank",
  },
];
