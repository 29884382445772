/**
 * Generated by orval
 * Do not edit manually.
 * Stepler API for the Admin
 * This is the documentation for the Stepler Admin.
 * OpenAPI spec version: 1.0.0
 */

export type PlatformType = typeof PlatformType[keyof typeof PlatformType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PlatformType = {
  Android: 'Android',
  iOS: 'iOS',
} as const;
