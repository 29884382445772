import React from "react";

import { Form } from "../form/Form";
import { PaginationControls } from "../pagination-controls/PaginationControls";
import { OldTable } from "../table/Table";

import { FormConfig, PaginationConfig, TableConfig } from "./types";

type Props = {
  form?: FormConfig;
  table: TableConfig;
  pagination?: PaginationConfig;
  children?: React.ReactNode;
};

export const TabPage = ({ form, table, pagination, children }: Props) => {
  return (
    <>
      {form && (
        <Form name={form.name} fields={form.fields} onSubmit={form.onSubmit} submitText={form.submitText} showStayOnPage={form.showStayOnPage} />
      )}
      {children}
      <OldTable
        tableConfig={table.tableBuild}
        objects={table.objects}
        link={table.link}
        emptyMessage={table.emptyMessage}
        tableTitle={table.tableTitle}
        tableStyles={table.tableStyles}
        id={table.id}
      />
      {pagination && (
        <PaginationControls
          page={pagination.page}
          limit={pagination.limit}
          total={pagination.total}
          hasNext={pagination.hasNext}
          hasPrevious={pagination.hasPrevious}
        />
      )}
    </>
  );
};
