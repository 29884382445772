/**
 * Generated by orval
 * Do not edit manually.
 * Stepler API for the Admin
 * This is the documentation for the Stepler Admin.
 * OpenAPI spec version: 1.0.0
 */
import {
  useMutation,
  useQuery,
  useSuspenseQuery
} from '@tanstack/react-query'
import type {
  DefinedInitialDataOptions,
  DefinedUseQueryResult,
  MutationFunction,
  QueryFunction,
  QueryKey,
  UndefinedInitialDataOptions,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
  UseSuspenseQueryOptions,
  UseSuspenseQueryResult
} from '@tanstack/react-query'
import type {
  CreateTestUsersBody,
  DeleteUserV1UsersUserIdDeleteParams,
  Error400Response,
  HTTPValidationError,
  PaginatedUsers,
  SearchUsersV1UsersGetParams,
  UpdateUserProfileInput,
  UpdateUserRoleInput,
  UpdateUserStreakInput,
  UserModelInput,
  UserModelOutput,
  UserPointModel
} from '.././model'
import { axiosInstance } from '.././base';
import type { ErrorType, BodyType } from '.././base';


type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];


/**
 * Delete user
 * @summary Delete User
 */
export const deleteUserV1UsersUserIdDelete = (
    userId: string,
    params?: DeleteUserV1UsersUserIdDeleteParams,
 options?: SecondParameter<typeof axiosInstance>,) => {
      
      
      return axiosInstance<void>(
      {url: `/v1/users/${userId}/`, method: 'DELETE',
        params
    },
      options);
    }
  


export const getDeleteUserV1UsersUserIdDeleteMutationOptions = <TError = ErrorType<Error400Response | HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteUserV1UsersUserIdDelete>>, TError,{userId: string;params?: DeleteUserV1UsersUserIdDeleteParams}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof deleteUserV1UsersUserIdDelete>>, TError,{userId: string;params?: DeleteUserV1UsersUserIdDeleteParams}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteUserV1UsersUserIdDelete>>, {userId: string;params?: DeleteUserV1UsersUserIdDeleteParams}> = (props) => {
          const {userId,params} = props ?? {};

          return  deleteUserV1UsersUserIdDelete(userId,params,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteUserV1UsersUserIdDeleteMutationResult = NonNullable<Awaited<ReturnType<typeof deleteUserV1UsersUserIdDelete>>>
    
    export type DeleteUserV1UsersUserIdDeleteMutationError = ErrorType<Error400Response | HTTPValidationError>

    /**
 * @summary Delete User
 */
export const useDeleteUserV1UsersUserIdDelete = <TError = ErrorType<Error400Response | HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteUserV1UsersUserIdDelete>>, TError,{userId: string;params?: DeleteUserV1UsersUserIdDeleteParams}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof deleteUserV1UsersUserIdDelete>>,
        TError,
        {userId: string;params?: DeleteUserV1UsersUserIdDeleteParams},
        TContext
      > => {

      const mutationOptions = getDeleteUserV1UsersUserIdDeleteMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Get user by id
 * @summary Get User By Id
 */
export const getUserByIdV1UsersUserIdGet = (
    userId: string,
 options?: SecondParameter<typeof axiosInstance>,signal?: AbortSignal
) => {
      
      
      return axiosInstance<UserModelOutput>(
      {url: `/v1/users/${userId}/`, method: 'GET', signal
    },
      options);
    }
  

export const getGetUserByIdV1UsersUserIdGetQueryKey = (userId: string,) => {
    return [`/v1/users/${userId}/`] as const;
    }

    
export const getGetUserByIdV1UsersUserIdGetQueryOptions = <TData = Awaited<ReturnType<typeof getUserByIdV1UsersUserIdGet>>, TError = ErrorType<Error400Response | HTTPValidationError>>(userId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getUserByIdV1UsersUserIdGet>>, TError, TData>>, request?: SecondParameter<typeof axiosInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetUserByIdV1UsersUserIdGetQueryKey(userId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getUserByIdV1UsersUserIdGet>>> = ({ signal }) => getUserByIdV1UsersUserIdGet(userId, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(userId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getUserByIdV1UsersUserIdGet>>, TError, TData> & { queryKey: QueryKey }
}

export type GetUserByIdV1UsersUserIdGetQueryResult = NonNullable<Awaited<ReturnType<typeof getUserByIdV1UsersUserIdGet>>>
export type GetUserByIdV1UsersUserIdGetQueryError = ErrorType<Error400Response | HTTPValidationError>


export function useGetUserByIdV1UsersUserIdGet<TData = Awaited<ReturnType<typeof getUserByIdV1UsersUserIdGet>>, TError = ErrorType<Error400Response | HTTPValidationError>>(
 userId: string, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getUserByIdV1UsersUserIdGet>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getUserByIdV1UsersUserIdGet>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof axiosInstance>}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetUserByIdV1UsersUserIdGet<TData = Awaited<ReturnType<typeof getUserByIdV1UsersUserIdGet>>, TError = ErrorType<Error400Response | HTTPValidationError>>(
 userId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getUserByIdV1UsersUserIdGet>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getUserByIdV1UsersUserIdGet>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof axiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetUserByIdV1UsersUserIdGet<TData = Awaited<ReturnType<typeof getUserByIdV1UsersUserIdGet>>, TError = ErrorType<Error400Response | HTTPValidationError>>(
 userId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getUserByIdV1UsersUserIdGet>>, TError, TData>>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
/**
 * @summary Get User By Id
 */

export function useGetUserByIdV1UsersUserIdGet<TData = Awaited<ReturnType<typeof getUserByIdV1UsersUserIdGet>>, TError = ErrorType<Error400Response | HTTPValidationError>>(
 userId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getUserByIdV1UsersUserIdGet>>, TError, TData>>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetUserByIdV1UsersUserIdGetQueryOptions(userId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetUserByIdV1UsersUserIdGetSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof getUserByIdV1UsersUserIdGet>>, TError = ErrorType<Error400Response | HTTPValidationError>>(userId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getUserByIdV1UsersUserIdGet>>, TError, TData>>, request?: SecondParameter<typeof axiosInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetUserByIdV1UsersUserIdGetQueryKey(userId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getUserByIdV1UsersUserIdGet>>> = ({ signal }) => getUserByIdV1UsersUserIdGet(userId, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(userId), ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof getUserByIdV1UsersUserIdGet>>, TError, TData> & { queryKey: QueryKey }
}

export type GetUserByIdV1UsersUserIdGetSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof getUserByIdV1UsersUserIdGet>>>
export type GetUserByIdV1UsersUserIdGetSuspenseQueryError = ErrorType<Error400Response | HTTPValidationError>


export function useGetUserByIdV1UsersUserIdGetSuspense<TData = Awaited<ReturnType<typeof getUserByIdV1UsersUserIdGet>>, TError = ErrorType<Error400Response | HTTPValidationError>>(
 userId: string, options: { query:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getUserByIdV1UsersUserIdGet>>, TError, TData>>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetUserByIdV1UsersUserIdGetSuspense<TData = Awaited<ReturnType<typeof getUserByIdV1UsersUserIdGet>>, TError = ErrorType<Error400Response | HTTPValidationError>>(
 userId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getUserByIdV1UsersUserIdGet>>, TError, TData>>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetUserByIdV1UsersUserIdGetSuspense<TData = Awaited<ReturnType<typeof getUserByIdV1UsersUserIdGet>>, TError = ErrorType<Error400Response | HTTPValidationError>>(
 userId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getUserByIdV1UsersUserIdGet>>, TError, TData>>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
/**
 * @summary Get User By Id
 */

export function useGetUserByIdV1UsersUserIdGetSuspense<TData = Awaited<ReturnType<typeof getUserByIdV1UsersUserIdGet>>, TError = ErrorType<Error400Response | HTTPValidationError>>(
 userId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getUserByIdV1UsersUserIdGet>>, TError, TData>>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetUserByIdV1UsersUserIdGetSuspenseQueryOptions(userId,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * Create a new user
 * @summary Create User
 */
export const createUserV1UsersPost = (
    userModelInput: BodyType<UserModelInput>,
 options?: SecondParameter<typeof axiosInstance>,) => {
      
      
      return axiosInstance<UserModelOutput>(
      {url: `/v1/users/`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: userModelInput
    },
      options);
    }
  


export const getCreateUserV1UsersPostMutationOptions = <TError = ErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createUserV1UsersPost>>, TError,{data: BodyType<UserModelInput>}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof createUserV1UsersPost>>, TError,{data: BodyType<UserModelInput>}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createUserV1UsersPost>>, {data: BodyType<UserModelInput>}> = (props) => {
          const {data} = props ?? {};

          return  createUserV1UsersPost(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type CreateUserV1UsersPostMutationResult = NonNullable<Awaited<ReturnType<typeof createUserV1UsersPost>>>
    export type CreateUserV1UsersPostMutationBody = BodyType<UserModelInput>
    export type CreateUserV1UsersPostMutationError = ErrorType<HTTPValidationError>

    /**
 * @summary Create User
 */
export const useCreateUserV1UsersPost = <TError = ErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createUserV1UsersPost>>, TError,{data: BodyType<UserModelInput>}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof createUserV1UsersPost>>,
        TError,
        {data: BodyType<UserModelInput>},
        TContext
      > => {

      const mutationOptions = getCreateUserV1UsersPostMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Search through users collection
 * @summary Search Users
 */
export const searchUsersV1UsersGet = (
    params?: SearchUsersV1UsersGetParams,
 options?: SecondParameter<typeof axiosInstance>,signal?: AbortSignal
) => {
      
      
      return axiosInstance<PaginatedUsers>(
      {url: `/v1/users/`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getSearchUsersV1UsersGetQueryKey = (params?: SearchUsersV1UsersGetParams,) => {
    return [`/v1/users/`, ...(params ? [params]: [])] as const;
    }

    
export const getSearchUsersV1UsersGetQueryOptions = <TData = Awaited<ReturnType<typeof searchUsersV1UsersGet>>, TError = ErrorType<Error400Response | HTTPValidationError>>(params?: SearchUsersV1UsersGetParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof searchUsersV1UsersGet>>, TError, TData>>, request?: SecondParameter<typeof axiosInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getSearchUsersV1UsersGetQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof searchUsersV1UsersGet>>> = ({ signal }) => searchUsersV1UsersGet(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof searchUsersV1UsersGet>>, TError, TData> & { queryKey: QueryKey }
}

export type SearchUsersV1UsersGetQueryResult = NonNullable<Awaited<ReturnType<typeof searchUsersV1UsersGet>>>
export type SearchUsersV1UsersGetQueryError = ErrorType<Error400Response | HTTPValidationError>


export function useSearchUsersV1UsersGet<TData = Awaited<ReturnType<typeof searchUsersV1UsersGet>>, TError = ErrorType<Error400Response | HTTPValidationError>>(
 params: undefined |  SearchUsersV1UsersGetParams, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof searchUsersV1UsersGet>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof searchUsersV1UsersGet>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof axiosInstance>}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useSearchUsersV1UsersGet<TData = Awaited<ReturnType<typeof searchUsersV1UsersGet>>, TError = ErrorType<Error400Response | HTTPValidationError>>(
 params?: SearchUsersV1UsersGetParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof searchUsersV1UsersGet>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof searchUsersV1UsersGet>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof axiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useSearchUsersV1UsersGet<TData = Awaited<ReturnType<typeof searchUsersV1UsersGet>>, TError = ErrorType<Error400Response | HTTPValidationError>>(
 params?: SearchUsersV1UsersGetParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof searchUsersV1UsersGet>>, TError, TData>>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
/**
 * @summary Search Users
 */

export function useSearchUsersV1UsersGet<TData = Awaited<ReturnType<typeof searchUsersV1UsersGet>>, TError = ErrorType<Error400Response | HTTPValidationError>>(
 params?: SearchUsersV1UsersGetParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof searchUsersV1UsersGet>>, TError, TData>>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getSearchUsersV1UsersGetQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getSearchUsersV1UsersGetSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof searchUsersV1UsersGet>>, TError = ErrorType<Error400Response | HTTPValidationError>>(params?: SearchUsersV1UsersGetParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof searchUsersV1UsersGet>>, TError, TData>>, request?: SecondParameter<typeof axiosInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getSearchUsersV1UsersGetQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof searchUsersV1UsersGet>>> = ({ signal }) => searchUsersV1UsersGet(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof searchUsersV1UsersGet>>, TError, TData> & { queryKey: QueryKey }
}

export type SearchUsersV1UsersGetSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof searchUsersV1UsersGet>>>
export type SearchUsersV1UsersGetSuspenseQueryError = ErrorType<Error400Response | HTTPValidationError>


export function useSearchUsersV1UsersGetSuspense<TData = Awaited<ReturnType<typeof searchUsersV1UsersGet>>, TError = ErrorType<Error400Response | HTTPValidationError>>(
 params: undefined |  SearchUsersV1UsersGetParams, options: { query:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof searchUsersV1UsersGet>>, TError, TData>>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useSearchUsersV1UsersGetSuspense<TData = Awaited<ReturnType<typeof searchUsersV1UsersGet>>, TError = ErrorType<Error400Response | HTTPValidationError>>(
 params?: SearchUsersV1UsersGetParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof searchUsersV1UsersGet>>, TError, TData>>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useSearchUsersV1UsersGetSuspense<TData = Awaited<ReturnType<typeof searchUsersV1UsersGet>>, TError = ErrorType<Error400Response | HTTPValidationError>>(
 params?: SearchUsersV1UsersGetParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof searchUsersV1UsersGet>>, TError, TData>>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
/**
 * @summary Search Users
 */

export function useSearchUsersV1UsersGetSuspense<TData = Awaited<ReturnType<typeof searchUsersV1UsersGet>>, TError = ErrorType<Error400Response | HTTPValidationError>>(
 params?: SearchUsersV1UsersGetParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof searchUsersV1UsersGet>>, TError, TData>>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getSearchUsersV1UsersGetSuspenseQueryOptions(params,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * Update user roles to the new list provided
 * @summary Update User Roles
 */
export const updateUserRolesV1UsersUserIdSetUserRolesPatch = (
    userId: string,
    updateUserRoleInput: BodyType<UpdateUserRoleInput>,
 options?: SecondParameter<typeof axiosInstance>,) => {
      
      
      return axiosInstance<UserModelOutput>(
      {url: `/v1/users/${userId}/set_user_roles/`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: updateUserRoleInput
    },
      options);
    }
  


export const getUpdateUserRolesV1UsersUserIdSetUserRolesPatchMutationOptions = <TError = ErrorType<Error400Response | HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateUserRolesV1UsersUserIdSetUserRolesPatch>>, TError,{userId: string;data: BodyType<UpdateUserRoleInput>}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof updateUserRolesV1UsersUserIdSetUserRolesPatch>>, TError,{userId: string;data: BodyType<UpdateUserRoleInput>}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateUserRolesV1UsersUserIdSetUserRolesPatch>>, {userId: string;data: BodyType<UpdateUserRoleInput>}> = (props) => {
          const {userId,data} = props ?? {};

          return  updateUserRolesV1UsersUserIdSetUserRolesPatch(userId,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type UpdateUserRolesV1UsersUserIdSetUserRolesPatchMutationResult = NonNullable<Awaited<ReturnType<typeof updateUserRolesV1UsersUserIdSetUserRolesPatch>>>
    export type UpdateUserRolesV1UsersUserIdSetUserRolesPatchMutationBody = BodyType<UpdateUserRoleInput>
    export type UpdateUserRolesV1UsersUserIdSetUserRolesPatchMutationError = ErrorType<Error400Response | HTTPValidationError>

    /**
 * @summary Update User Roles
 */
export const useUpdateUserRolesV1UsersUserIdSetUserRolesPatch = <TError = ErrorType<Error400Response | HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateUserRolesV1UsersUserIdSetUserRolesPatch>>, TError,{userId: string;data: BodyType<UpdateUserRoleInput>}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof updateUserRolesV1UsersUserIdSetUserRolesPatch>>,
        TError,
        {userId: string;data: BodyType<UpdateUserRoleInput>},
        TContext
      > => {

      const mutationOptions = getUpdateUserRolesV1UsersUserIdSetUserRolesPatchMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Update user profile
 * @summary Update User Profile
 */
export const updateUserProfileV1UsersUserIdUpdateUserProfilePatch = (
    userId: string,
    updateUserProfileInput: BodyType<UpdateUserProfileInput>,
 options?: SecondParameter<typeof axiosInstance>,) => {
      
      
      return axiosInstance<UserModelOutput>(
      {url: `/v1/users/${userId}/update_user_profile/`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: updateUserProfileInput
    },
      options);
    }
  


export const getUpdateUserProfileV1UsersUserIdUpdateUserProfilePatchMutationOptions = <TError = ErrorType<Error400Response | HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateUserProfileV1UsersUserIdUpdateUserProfilePatch>>, TError,{userId: string;data: BodyType<UpdateUserProfileInput>}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof updateUserProfileV1UsersUserIdUpdateUserProfilePatch>>, TError,{userId: string;data: BodyType<UpdateUserProfileInput>}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateUserProfileV1UsersUserIdUpdateUserProfilePatch>>, {userId: string;data: BodyType<UpdateUserProfileInput>}> = (props) => {
          const {userId,data} = props ?? {};

          return  updateUserProfileV1UsersUserIdUpdateUserProfilePatch(userId,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type UpdateUserProfileV1UsersUserIdUpdateUserProfilePatchMutationResult = NonNullable<Awaited<ReturnType<typeof updateUserProfileV1UsersUserIdUpdateUserProfilePatch>>>
    export type UpdateUserProfileV1UsersUserIdUpdateUserProfilePatchMutationBody = BodyType<UpdateUserProfileInput>
    export type UpdateUserProfileV1UsersUserIdUpdateUserProfilePatchMutationError = ErrorType<Error400Response | HTTPValidationError>

    /**
 * @summary Update User Profile
 */
export const useUpdateUserProfileV1UsersUserIdUpdateUserProfilePatch = <TError = ErrorType<Error400Response | HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateUserProfileV1UsersUserIdUpdateUserProfilePatch>>, TError,{userId: string;data: BodyType<UpdateUserProfileInput>}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof updateUserProfileV1UsersUserIdUpdateUserProfilePatch>>,
        TError,
        {userId: string;data: BodyType<UpdateUserProfileInput>},
        TContext
      > => {

      const mutationOptions = getUpdateUserProfileV1UsersUserIdUpdateUserProfilePatchMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Change the streak data
 * @summary Update User Streak Data
 */
export const updateUserStreakDataV1UsersUserIdUpdateUserStreakDataPatch = (
    userId: string,
    updateUserStreakInput: BodyType<UpdateUserStreakInput>,
 options?: SecondParameter<typeof axiosInstance>,) => {
      
      
      return axiosInstance<UserModelOutput>(
      {url: `/v1/users/${userId}/update_user_streak_data/`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: updateUserStreakInput
    },
      options);
    }
  


export const getUpdateUserStreakDataV1UsersUserIdUpdateUserStreakDataPatchMutationOptions = <TError = ErrorType<Error400Response | HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateUserStreakDataV1UsersUserIdUpdateUserStreakDataPatch>>, TError,{userId: string;data: BodyType<UpdateUserStreakInput>}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof updateUserStreakDataV1UsersUserIdUpdateUserStreakDataPatch>>, TError,{userId: string;data: BodyType<UpdateUserStreakInput>}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateUserStreakDataV1UsersUserIdUpdateUserStreakDataPatch>>, {userId: string;data: BodyType<UpdateUserStreakInput>}> = (props) => {
          const {userId,data} = props ?? {};

          return  updateUserStreakDataV1UsersUserIdUpdateUserStreakDataPatch(userId,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type UpdateUserStreakDataV1UsersUserIdUpdateUserStreakDataPatchMutationResult = NonNullable<Awaited<ReturnType<typeof updateUserStreakDataV1UsersUserIdUpdateUserStreakDataPatch>>>
    export type UpdateUserStreakDataV1UsersUserIdUpdateUserStreakDataPatchMutationBody = BodyType<UpdateUserStreakInput>
    export type UpdateUserStreakDataV1UsersUserIdUpdateUserStreakDataPatchMutationError = ErrorType<Error400Response | HTTPValidationError>

    /**
 * @summary Update User Streak Data
 */
export const useUpdateUserStreakDataV1UsersUserIdUpdateUserStreakDataPatch = <TError = ErrorType<Error400Response | HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateUserStreakDataV1UsersUserIdUpdateUserStreakDataPatch>>, TError,{userId: string;data: BodyType<UpdateUserStreakInput>}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof updateUserStreakDataV1UsersUserIdUpdateUserStreakDataPatch>>,
        TError,
        {userId: string;data: BodyType<UpdateUserStreakInput>},
        TContext
      > => {

      const mutationOptions = getUpdateUserStreakDataV1UsersUserIdUpdateUserStreakDataPatchMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Create test users that are used for testing only
 * @summary Create Test Users
 */
export const createTestUsersV1UsersCreateTestUsersPost = (
    createTestUsersBody: BodyType<CreateTestUsersBody>,
 options?: SecondParameter<typeof axiosInstance>,) => {
      
      
      return axiosInstance<string[]>(
      {url: `/v1/users/create_test_users/`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: createTestUsersBody
    },
      options);
    }
  


export const getCreateTestUsersV1UsersCreateTestUsersPostMutationOptions = <TError = ErrorType<Error400Response | HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createTestUsersV1UsersCreateTestUsersPost>>, TError,{data: BodyType<CreateTestUsersBody>}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof createTestUsersV1UsersCreateTestUsersPost>>, TError,{data: BodyType<CreateTestUsersBody>}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createTestUsersV1UsersCreateTestUsersPost>>, {data: BodyType<CreateTestUsersBody>}> = (props) => {
          const {data} = props ?? {};

          return  createTestUsersV1UsersCreateTestUsersPost(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type CreateTestUsersV1UsersCreateTestUsersPostMutationResult = NonNullable<Awaited<ReturnType<typeof createTestUsersV1UsersCreateTestUsersPost>>>
    export type CreateTestUsersV1UsersCreateTestUsersPostMutationBody = BodyType<CreateTestUsersBody>
    export type CreateTestUsersV1UsersCreateTestUsersPostMutationError = ErrorType<Error400Response | HTTPValidationError>

    /**
 * @summary Create Test Users
 */
export const useCreateTestUsersV1UsersCreateTestUsersPost = <TError = ErrorType<Error400Response | HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createTestUsersV1UsersCreateTestUsersPost>>, TError,{data: BodyType<CreateTestUsersBody>}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof createTestUsersV1UsersCreateTestUsersPost>>,
        TError,
        {data: BodyType<CreateTestUsersBody>},
        TContext
      > => {

      const mutationOptions = getCreateTestUsersV1UsersCreateTestUsersPostMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Get user points in each source type
 * @summary User Point
 */
export const userPointV1UsersUserIdPointsGet = (
    userId: string,
 options?: SecondParameter<typeof axiosInstance>,signal?: AbortSignal
) => {
      
      
      return axiosInstance<UserPointModel>(
      {url: `/v1/users/${userId}/points/`, method: 'GET', signal
    },
      options);
    }
  

export const getUserPointV1UsersUserIdPointsGetQueryKey = (userId: string,) => {
    return [`/v1/users/${userId}/points/`] as const;
    }

    
export const getUserPointV1UsersUserIdPointsGetQueryOptions = <TData = Awaited<ReturnType<typeof userPointV1UsersUserIdPointsGet>>, TError = ErrorType<Error400Response | HTTPValidationError>>(userId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof userPointV1UsersUserIdPointsGet>>, TError, TData>>, request?: SecondParameter<typeof axiosInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getUserPointV1UsersUserIdPointsGetQueryKey(userId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof userPointV1UsersUserIdPointsGet>>> = ({ signal }) => userPointV1UsersUserIdPointsGet(userId, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(userId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof userPointV1UsersUserIdPointsGet>>, TError, TData> & { queryKey: QueryKey }
}

export type UserPointV1UsersUserIdPointsGetQueryResult = NonNullable<Awaited<ReturnType<typeof userPointV1UsersUserIdPointsGet>>>
export type UserPointV1UsersUserIdPointsGetQueryError = ErrorType<Error400Response | HTTPValidationError>


export function useUserPointV1UsersUserIdPointsGet<TData = Awaited<ReturnType<typeof userPointV1UsersUserIdPointsGet>>, TError = ErrorType<Error400Response | HTTPValidationError>>(
 userId: string, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof userPointV1UsersUserIdPointsGet>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof userPointV1UsersUserIdPointsGet>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof axiosInstance>}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useUserPointV1UsersUserIdPointsGet<TData = Awaited<ReturnType<typeof userPointV1UsersUserIdPointsGet>>, TError = ErrorType<Error400Response | HTTPValidationError>>(
 userId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof userPointV1UsersUserIdPointsGet>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof userPointV1UsersUserIdPointsGet>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof axiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useUserPointV1UsersUserIdPointsGet<TData = Awaited<ReturnType<typeof userPointV1UsersUserIdPointsGet>>, TError = ErrorType<Error400Response | HTTPValidationError>>(
 userId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof userPointV1UsersUserIdPointsGet>>, TError, TData>>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
/**
 * @summary User Point
 */

export function useUserPointV1UsersUserIdPointsGet<TData = Awaited<ReturnType<typeof userPointV1UsersUserIdPointsGet>>, TError = ErrorType<Error400Response | HTTPValidationError>>(
 userId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof userPointV1UsersUserIdPointsGet>>, TError, TData>>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getUserPointV1UsersUserIdPointsGetQueryOptions(userId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getUserPointV1UsersUserIdPointsGetSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof userPointV1UsersUserIdPointsGet>>, TError = ErrorType<Error400Response | HTTPValidationError>>(userId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof userPointV1UsersUserIdPointsGet>>, TError, TData>>, request?: SecondParameter<typeof axiosInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getUserPointV1UsersUserIdPointsGetQueryKey(userId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof userPointV1UsersUserIdPointsGet>>> = ({ signal }) => userPointV1UsersUserIdPointsGet(userId, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(userId), ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof userPointV1UsersUserIdPointsGet>>, TError, TData> & { queryKey: QueryKey }
}

export type UserPointV1UsersUserIdPointsGetSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof userPointV1UsersUserIdPointsGet>>>
export type UserPointV1UsersUserIdPointsGetSuspenseQueryError = ErrorType<Error400Response | HTTPValidationError>


export function useUserPointV1UsersUserIdPointsGetSuspense<TData = Awaited<ReturnType<typeof userPointV1UsersUserIdPointsGet>>, TError = ErrorType<Error400Response | HTTPValidationError>>(
 userId: string, options: { query:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof userPointV1UsersUserIdPointsGet>>, TError, TData>>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useUserPointV1UsersUserIdPointsGetSuspense<TData = Awaited<ReturnType<typeof userPointV1UsersUserIdPointsGet>>, TError = ErrorType<Error400Response | HTTPValidationError>>(
 userId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof userPointV1UsersUserIdPointsGet>>, TError, TData>>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useUserPointV1UsersUserIdPointsGetSuspense<TData = Awaited<ReturnType<typeof userPointV1UsersUserIdPointsGet>>, TError = ErrorType<Error400Response | HTTPValidationError>>(
 userId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof userPointV1UsersUserIdPointsGet>>, TError, TData>>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
/**
 * @summary User Point
 */

export function useUserPointV1UsersUserIdPointsGetSuspense<TData = Awaited<ReturnType<typeof userPointV1UsersUserIdPointsGet>>, TError = ErrorType<Error400Response | HTTPValidationError>>(
 userId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof userPointV1UsersUserIdPointsGet>>, TError, TData>>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getUserPointV1UsersUserIdPointsGetSuspenseQueryOptions(userId,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



