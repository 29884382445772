import React, { ReactNode } from "react";
import { useState } from "react";
import { useSuspenseQuery } from "@tanstack/react-query";
import { createFileRoute, useNavigate } from "@tanstack/react-router";
import { AxiosError } from "axios";
import { toast } from "sonner";

import Tab from "@/tab";
import { Card } from "@/ui/card";

import {
  getGetMarketConfigByIdV1MarketConfigMarketConfigIdGetSuspenseQueryOptions,
  useDeleteMarketConfigV1MarketConfigMarketConfigIdDelete,
  useUpdateMarketConfigV1MarketConfigMarketConfigIdPatch,
} from "~/api/market-config/market-config.gen";
import { MarketRewardsConfigSubModel } from "~/api/model";
import { getCountryName } from "~/helpers/country-list";
import { parseFastAPIError } from "~/helpers/parse-errors";
import { Form } from "~/oldComponents/form/Form";
import { Field } from "~/oldComponents/form/types";

export const Route = createFileRoute("/_auth/markets/$marketId/rewards")({
  loader: ({ context: { queryClient }, params: { marketId } }) => {
    return queryClient.ensureQueryData(getGetMarketConfigByIdV1MarketConfigMarketConfigIdGetSuspenseQueryOptions(marketId));
  },
  component: () => <UpdateMarketPage />,
});

function UpdateMarketPage() {
  const navigate = useNavigate();

  const marketId = Route.useParams().marketId;

  const { data: market } = useSuspenseQuery(getGetMarketConfigByIdV1MarketConfigMarketConfigIdGetSuspenseQueryOptions(marketId));
  const [rewards, setRewards] = useState<MarketRewardsConfigSubModel[]>(market.rewards);

  const updateMarketMutation = useUpdateMarketConfigV1MarketConfigMarketConfigIdPatch({
    mutation: {
      onSuccess: () => {
        toast("market successfully updated!");
        navigate({ to: "/markets" });
      },
      onError: (error: AxiosError) => {
        toast.error("Error", { description: parseFastAPIError(error) as ReactNode });
      },
    },
  });

  const deleteMarketMutation = useDeleteMarketConfigV1MarketConfigMarketConfigIdDelete({
    mutation: {
      onSuccess: () => {
        toast("market successfully deleted!");
        navigate({ to: "/markets" });
      },
      onError: (error: AxiosError) => {
        toast.error("Error", { description: parseFastAPIError(error) as ReactNode });
      },
    },
  });

  const onMarketDelete = async () => {
    await deleteMarketMutation.mutateAsync({ marketConfigId: marketId });
  };

  const onSubmit = async (event: React.FormEvent | undefined) => {
    event?.preventDefault();
    if (!market) return;

    await updateMarketMutation.mutateAsync({
      marketConfigId: marketId,
      data: {
        ...market,
        rewards: rewards,
      },
    });
  };

  const rewardFields: Field[] = [
    {
      name: "WatchingVideo",
      label: "WatchingVideo:",
      type: "number",
      helpText: "The amount of point given for claiming the Daily reward",
      value: rewards.find((reward: MarketRewardsConfigSubModel) => reward.source === "WatchingVideo")?.amount || 10,
      change: (event: React.ChangeEvent<HTMLInputElement>) => {
        const index = rewards.findIndex((reward: MarketRewardsConfigSubModel) => reward.source === "WatchingVideo");
        if (index !== -1) {
          setRewards((rewards) => {
            rewards[index].amount = +event.target.value;
            return rewards;
          });
        }
      },
      min: 1,
      required: true,
    },

    {
      name: "TwoAndAHalfThousandReward",
      label: "2.5 Thousand steps:",
      type: "number",
      helpText: "The amount of points given for claiming the 2.5k steps reward. (AB testing)",
      value: rewards.find((reward: MarketRewardsConfigSubModel) => reward.source === "TwoAndAHalfThousandReward")?.amount || 3,
      change: (event: React.ChangeEvent<HTMLInputElement>) => {
        const index = rewards.findIndex((reward: MarketRewardsConfigSubModel) => reward.source === "TwoAndAHalfThousandReward");
        if (index !== -1) {
          setRewards((rewards) => {
            rewards[index].amount = +event.target.value;
            return rewards;
          });
        }
      },
      min: 1,
      required: true,
    },
    {
      name: "FiveThousandReward",
      label: "5 Thousand steps:",
      type: "number",
      helpText: "The amount of points given for claiming the 5k steps reward. (AB testing)",
      value: rewards.find((reward: MarketRewardsConfigSubModel) => reward.source === "FiveThousandReward")?.amount || 3,
      change: (event: React.ChangeEvent<HTMLInputElement>) => {
        const index = rewards.findIndex((reward: MarketRewardsConfigSubModel) => reward.source === "FiveThousandReward");
        if (index !== -1) {
          setRewards((rewards) => {
            rewards[index].amount = +event.target.value;
            return rewards;
          });
        }
      },
      min: 1,
      required: true,
    },
    {
      name: "FiveKReward",
      label: "FiveKReward:",
      type: "number",
      helpText: "The amount of points given for claiming the 5k steps reward.",
      value: rewards.find((reward: MarketRewardsConfigSubModel) => reward.source === "FiveKReward")?.amount || 10,
      change: (event: React.ChangeEvent<HTMLInputElement>) => {
        const index = rewards.findIndex((reward: MarketRewardsConfigSubModel) => reward.source === "FiveKReward");
        if (index !== -1) {
          setRewards((rewards) => {
            rewards[index].amount = +event.target.value;
            return rewards;
          });
        }
      },
      min: 1,
      required: true,
    },
    {
      name: "SevenAndAHalfThousandReward",
      label: "7.5 Thousand steps:",
      type: "number",
      helpText: "The amount of points given for claiming the 7.5k steps reward. (AB testing)",
      value: rewards.find((reward: MarketRewardsConfigSubModel) => reward.source === "SevenAndAHalfThousandReward")?.amount || 5,
      change: (event: React.ChangeEvent<HTMLInputElement>) => {
        const index = rewards.findIndex((reward: MarketRewardsConfigSubModel) => reward.source === "SevenAndAHalfThousandReward");
        if (index !== -1) {
          setRewards((rewards) => {
            rewards[index].amount = +event.target.value;
            return rewards;
          });
        }
      },
      min: 1,
      required: true,
    },
    {
      name: "TenKReward",
      label: "TenKReward:",
      type: "number",
      helpText: "The amount of points given for claiming the 10k steps reward.",
      value: rewards.find((reward: MarketRewardsConfigSubModel) => reward.source === "TenKReward")?.amount || 10,
      change: (event: React.ChangeEvent<HTMLInputElement>) => {
        const index = rewards.findIndex((reward: MarketRewardsConfigSubModel) => reward.source === "TenKReward");
        if (index !== -1) {
          setRewards((rewards) => {
            rewards[index].amount = +event.target.value;
            return rewards;
          });
        }
      },
      min: 1,
      required: true,
    },
    {
      name: "TwelveAndAHalfThousandReward",
      label: "12.5 Thousand steps:",
      type: "number",
      helpText: "The amount of points given for claiming the 12.5k steps reward. (AB testing)",
      value: rewards.find((reward: MarketRewardsConfigSubModel) => reward.source === "TwelveAndAHalfThousandReward")?.amount || 5,
      change: (event: React.ChangeEvent<HTMLInputElement>) => {
        const index = rewards.findIndex((reward: MarketRewardsConfigSubModel) => reward.source === "TwelveAndAHalfThousandReward");
        if (index !== -1) {
          setRewards((rewards) => {
            rewards[index].amount = +event.target.value;
            return rewards;
          });
        }
      },
      min: 1,
      required: true,
    },
    {
      name: "FifteenKReward",
      label: "FifteenKReward:",
      type: "number",
      helpText: "The amount of points given for claiming the 15k steps reward.",
      value: rewards.find((reward: MarketRewardsConfigSubModel) => reward.source === "FifteenKReward")?.amount || 10,
      change: (event: React.ChangeEvent<HTMLInputElement>) => {
        const index = rewards.findIndex((reward: MarketRewardsConfigSubModel) => reward.source === "FifteenKReward");
        if (index !== -1) {
          setRewards((rewards) => {
            rewards[index].amount = +event.target.value;
            return rewards;
          });
        }
      },
      min: 1,
      required: true,
    },
    {
      name: "TwentyKReward",
      label: "TwentyKReward:",
      type: "number",
      helpText: "The amount of points given for claiming the 20k steps reward.",
      value: rewards.find((reward: MarketRewardsConfigSubModel) => reward.source === "TwentyKReward")?.amount || 10,
      change: (event: React.ChangeEvent<HTMLInputElement>) => {
        const index = rewards.findIndex((reward: MarketRewardsConfigSubModel) => reward.source === "TwentyKReward");
        if (index !== -1) {
          setRewards((rewards) => {
            rewards[index].amount = +event.target.value;
            return rewards;
          });
        }
      },
      min: 1,
      required: true,
    },
    {
      name: "ThirtyKReward",
      label: "ThirtyKReward:",
      type: "number",
      helpText: "The amount of points given for claiming the 30k steps reward.",
      value: rewards.find((reward: MarketRewardsConfigSubModel) => reward.source === "ThirtyKReward")?.amount || 10,
      change: (event: React.ChangeEvent<HTMLInputElement>) => {
        const index = rewards.findIndex((reward: MarketRewardsConfigSubModel) => reward.source === "ThirtyKReward");
        if (index !== -1) {
          setRewards((rewards) => {
            rewards[index].amount = +event.target.value;
            return rewards;
          });
        }
      },
      min: 1,
      required: true,
    },
    {
      name: "FourtyKReward",
      label: "FourtyKReward:",
      type: "number",
      helpText: "The amount of points given for claiming the 40k steps reward.",
      value: rewards.find((reward: MarketRewardsConfigSubModel) => reward.source === "FourtyKReward")?.amount || 10,
      change: (event: React.ChangeEvent<HTMLInputElement>) => {
        const index = rewards.findIndex((reward: MarketRewardsConfigSubModel) => reward.source === "FourtyKReward");
        if (index !== -1) {
          setRewards((rewards) => {
            rewards[index].amount = +event.target.value;
            return rewards;
          });
        }
      },
      min: 1,
      required: true,
    },
    {
      name: "ProfileCompletion",
      label: "ProfileCompletion:",
      type: "number",
      helpText: "The amount of points given when the user fills in the profile fully.",
      value: rewards.find((reward: MarketRewardsConfigSubModel) => reward.source === "ProfileCompletion")?.amount || 20,
      change: (event: React.ChangeEvent<HTMLInputElement>) => {
        const index = rewards.findIndex((reward: MarketRewardsConfigSubModel) => reward.source === "ProfileCompletion");
        if (index !== -1) {
          setRewards((rewards) => {
            rewards[index].amount = +event.target.value;
            return rewards;
          });
        }
      },
      min: 1,
      required: true,
    },
    {
      name: "InstagramVisitReward",
      label: "InstagramVisitReward:",
      type: "number",
      helpText: "The amount of points given for visiting Steplers Instagram account.",
      value: rewards.find((reward: MarketRewardsConfigSubModel) => reward.source === "InstagramVisitReward")?.amount || 10,
      change: (event: React.ChangeEvent<HTMLInputElement>) => {
        const index = rewards.findIndex((reward: MarketRewardsConfigSubModel) => reward.source === "InstagramVisitReward");
        if (index !== -1) {
          setRewards((rewards) => {
            rewards[index].amount = +event.target.value;
            return rewards;
          });
        }
      },
      min: 1,
      required: true,
    },
    {
      name: "FacebookVisitReward",
      label: "FacebookVisitReward:",
      type: "number",
      helpText: "The amount of points given for visiting Steplers Facebook account.",
      value: rewards.find((reward: MarketRewardsConfigSubModel) => reward.source === "FacebookVisitReward")?.amount || 10,
      change: (event: React.ChangeEvent<HTMLInputElement>) => {
        const index = rewards.findIndex((reward: MarketRewardsConfigSubModel) => reward.source === "FacebookVisitReward");
        if (index !== -1) {
          setRewards((rewards) => {
            rewards[index].amount = +event.target.value;
            return rewards;
          });
        }
      },
      min: 1,
      required: true,
    },
    {
      name: "TikTokVisitReward",
      label: "TikTokVisitReward:",
      type: "number",
      helpText: "The amount of points given for visiting Steplers TikTok account.",
      value: rewards.find((reward: MarketRewardsConfigSubModel) => reward.source === "TikTokVisitReward")?.amount || 10,
      change: (event: React.ChangeEvent<HTMLInputElement>) => {
        const index = rewards.findIndex((reward: MarketRewardsConfigSubModel) => reward.source === "TikTokVisitReward");
        if (index !== -1) {
          setRewards((rewards) => {
            rewards[index].amount = +event.target.value;
            return rewards;
          });
        }
      },
      min: 1,
      required: true,
    },
    {
      name: "UserInvite",
      label: "UserInvite:",
      type: "number",
      // helpText: 'The amount of points given for inviting a new user that signs up.',
      helpText: "The amount of points can be changed in the general config.",
      disabled: true,
      value: rewards.find((reward: MarketRewardsConfigSubModel) => reward.source === "UserInvite")?.amount || 10,
      change: (event: React.ChangeEvent<HTMLInputElement>) => {
        const index = rewards.findIndex((reward: MarketRewardsConfigSubModel) => reward.source === "UserInvite");
        if (index !== -1) {
          setRewards((rewards) => {
            rewards[index].amount = +event.target.value;
            return rewards;
          });
        }
      },
      min: 1,
      required: true,
    },
    {
      name: "AcceptInvite",
      label: "AcceptInvite:",
      type: "number",
      // helpText: 'The amount of points given for accepting a user invite.',
      helpText: "The amount of points can be changed in the general config.",
      disabled: true,
      value: rewards.find((reward: MarketRewardsConfigSubModel) => reward.source === "AcceptInvite")?.amount || 10,
      change: (event: React.ChangeEvent<HTMLInputElement>) => {
        const index = rewards.findIndex((reward: MarketRewardsConfigSubModel) => reward.source === "AcceptInvite");
        if (index !== -1) {
          setRewards((rewards) => {
            rewards[index].amount = +event.target.value;
            return rewards;
          });
        }
      },
      min: 1,
      required: true,
    },
    {
      name: "AppleAttachment",
      label: "AppleAttachment:",
      type: "number",
      helpText: "The amount of points can be changed in the general config.",
      // helpText: 'The amount of points given for connecting an Apple account.',
      disabled: true,
      value: rewards.find((reward: MarketRewardsConfigSubModel) => reward.source === "AppleAttachment")?.amount || 10,
      change: (event: React.ChangeEvent<HTMLInputElement>) => {
        const index = rewards.findIndex((reward: MarketRewardsConfigSubModel) => reward.source === "AppleAttachment");
        if (index !== -1) {
          setRewards((rewards) => {
            rewards[index].amount = +event.target.value;
            return rewards;
          });
        }
      },
      min: 1,
      required: true,
    },
    {
      name: "GoogleAttachment",
      label: "GoogleAttachment:",
      type: "number",
      helpText: "The amount of points can be changed in the general config.",
      // helpText: 'The amount of points given for connecting an Google account.',
      disabled: true,
      value: rewards.find((reward: MarketRewardsConfigSubModel) => reward.source === "GoogleAttachment")?.amount || 10,
      change: (event: React.ChangeEvent<HTMLInputElement>) => {
        const index = rewards.findIndex((reward: MarketRewardsConfigSubModel) => reward.source === "GoogleAttachment");
        if (index !== -1) {
          setRewards((rewards) => {
            rewards[index].amount = +event.target.value;
            return rewards;
          });
        }
      },
      min: 1,
      required: true,
    },
    {
      name: "FacebookAttachment",
      label: "FacebookAttachment:",
      type: "number",
      helpText: "The amount of points can be changed in the general config.",
      // helpText: 'The amount of points given for connecting an Facebook account.',
      disabled: true,
      value: rewards.find((reward: MarketRewardsConfigSubModel) => reward.source === "FacebookAttachment")?.amount || 10,
      change: (event: React.ChangeEvent<HTMLInputElement>) => {
        const index = rewards.findIndex((reward: MarketRewardsConfigSubModel) => reward.source === "FacebookAttachment");
        if (index !== -1) {
          setRewards((rewards) => {
            rewards[index].amount = +event.target.value;
            return rewards;
          });
        }
      },
      min: 1,
      required: true,
    },
  ];

  return (
    <section className="flex flex-col gap-10 bg-background px-8 py-10">
      <section className="flex items-start justify-between">
        <div className="header-titles_wrapper gap-4">
          <h2 className="text-foreground">Edit Market {getCountryName(market.countryCode)}</h2>
        </div>
        <div>
          <button onClick={onMarketDelete} className="button button--danger">
            Delete market
          </button>
        </div>
      </section>
      <div>
        <div className="flex w-full gap-2">
          <Tab active={false} to={`/markets/${market._id}`} title="General" />
          <Tab active={true} to={`/markets/${market._id}/rewards`} title="Rewards" />
          <Tab active={false} to={`/markets/${market._id}/leaderboards`} title="Leaderboard" />
        </div>
        <Card className="bg-card px-4 py-10 text-card-foreground">
          <Form vertical={true} name="rewards" onSubmit={onSubmit} submitText="Save market" fields={rewardFields} />
        </Card>
      </div>
    </section>
  );
}
