import React, { ReactNode } from "react";
import { useState } from "react";
import { createFileRoute, useNavigate } from "@tanstack/react-router";
import { AxiosError } from "axios";
import { toast } from "sonner";

import { useCreateInviteCampaignV1InviteCampaignsPost } from "~/api/invite-campaigns/invite-campaigns.gen";
import { InviteCampaignModel } from "~/api/model";
import { useGetOffersV1OffersGetSuspense } from "~/api/offers/offers.gen";
import { getCountryOptions } from "~/helpers/country-list";
import { parseFastAPIError } from "~/helpers/parse-errors";
import { CreateEditPage } from "~/oldComponents/layout/CreateEditLayout";
import { FormConfig, PageConfig } from "~/oldComponents/layout/types";

export const Route = createFileRoute("/_auth/invite-campaigns/create")({
  component: () => <CreateInviteCampaignPage />,
});

function CreateInviteCampaignPage() {
  const navigate = useNavigate();

  const [inviteCampaign, setInviteCampaign] = useState<InviteCampaignModel>({
    country: "",
    title: "",
    description: "",
    campaignLimit: 0,
    startDate: new Date().toISOString(),
    endDate: new Date().toISOString(),
    offerId: "",
    invitesCount: 1,
    bannerUrl: "",
    thumbnailUrl: "",
  });

  const createCampaginMutation = useCreateInviteCampaignV1InviteCampaignsPost({
    mutation: {
      onSuccess: () => {
        toast("Invite campaign was successfully created!");
        navigate({ to: `/invite-campaigns?status=Inactive&country=${inviteCampaign.country}` });
      },
      onError: (error: AxiosError) => {
        toast.error("Error", { description: parseFastAPIError(error) as ReactNode });
      },
    },
  });

  const { data: offers } = useGetOffersV1OffersGetSuspense(
    { country: inviteCampaign.country },
    {
      query: {
        initialData: [],
      },
    },
  );

  const onSubmit = async (event: React.FormEvent | undefined) => {
    event?.preventDefault();
    if (!inviteCampaign.bannerUrl || !inviteCampaign.thumbnailUrl) {
      return toast.error("Error", { description: "Banner & thumbnail are required!" });
    }
    await createCampaginMutation.mutateAsync({ data: inviteCampaign });
  };

  const page: PageConfig = {
    title: "Create invite campaign",
    description: "Use this elements, if you want to show some hints or additional information",
  };

  const form: FormConfig = {
    fields: [
      {
        name: "country",
        label: "Country:",
        type: "search-select",
        helpText: "Specify campaign country.",
        change: (data: string) => {
          setInviteCampaign({ ...inviteCampaign, country: data });
        },
        value: inviteCampaign.country,
        options: getCountryOptions(),
        empty: " ",
        row: true,
        required: true,
      },
      {
        name: "title",
        label: "Title:",
        type: "text",
        helpText: "Campaign title.",
        value: inviteCampaign.title,
        change: (event: React.ChangeEvent<HTMLInputElement>) => setInviteCampaign({ ...inviteCampaign, title: event.target.value }),
        row: true,
        required: true,
      },
      {
        name: "description",
        label: "Description:",
        helpText: "Campaign description.",
        type: "text-editor",
        change: (data: string) => setInviteCampaign({ ...inviteCampaign, description: data }),
        value: inviteCampaign.description,
        row: true,
        required: true,
        custom: true,
      },
      {
        name: "invitesCount",
        label: "Invites count:",
        type: "number",
        helpText: "How many friends user need to invite to be rewarded.",
        value: inviteCampaign.invitesCount,
        change: (event: React.ChangeEvent<HTMLInputElement>) => setInviteCampaign({ ...inviteCampaign, invitesCount: +event.target.value }),
        row: true,
        min: 1,
        required: true,
      },
      {
        name: "offerId",
        label: "OfferId:",
        type: "select",
        helpText: "Reward for completing campaign.",
        change: (e: React.ChangeEvent<HTMLInputElement>) => setInviteCampaign({ ...inviteCampaign, offerId: e.target.value }),
        value: inviteCampaign.offerId,
        options: offers
          ?.sort((offerA, offerB) => (offerA.titleV2.toLowerCase() < offerB.titleV2.toLowerCase() ? -1 : 1))
          .map((offer) => ({ name: `${offer.titleV2} - ${offer.subTitle}`, value: offer._id })),
        empty: " ",
        row: true,
        required: true,
      },
      {
        name: "campaignLimit",
        label: "Campaign limit:",
        type: "number",
        helpText: "Campaign limit defines how many times user can receive reward. After reaching limit user will receive only invite points.",
        value: inviteCampaign.campaignLimit,
        change: (e: React.ChangeEvent<HTMLInputElement>) => setInviteCampaign({ ...inviteCampaign, campaignLimit: +e.target.value }),
        row: true,
        min: 1,
      },
      {
        name: "startDate",
        label: "Start date:",
        helpText: "Start of the period when campaign will be active.",
        type: "datetime-picker",
        value: inviteCampaign.startDate,
        change: (date: Date) => setInviteCampaign({ ...inviteCampaign, startDate: date.toISOString() }),
        custom: true,
      },
      {
        name: "endDate",
        label: "End date:",
        helpText: "End of the period when campaign will be disabled.",
        type: "datetime-picker",
        value: inviteCampaign.endDate,
        change: (date: Date) => setInviteCampaign({ ...inviteCampaign, endDate: date.toISOString() }),
        custom: true,
      },
      {
        name: "banner",
        label: "Banner:",
        helpText: "Campaign banner.",
        type: "image-cropper",
        imageType: "invite_campaign_banner",
        value: inviteCampaign.bannerUrl,
        change: (imageUrl: string) => setInviteCampaign({ ...inviteCampaign, bannerUrl: imageUrl }),
        row: true,
        custom: true,
        required: true,
      },
      {
        name: "thumbnail",
        label: "Thumbnail:",
        helpText: "Campaign thumbnail.",
        type: "image-cropper",
        imageType: "invite_campaign_thumbnail",
        value: inviteCampaign.thumbnailUrl,
        change: (imageUrl: string | null) => setInviteCampaign({ ...inviteCampaign, thumbnailUrl: imageUrl }),
        row: true,
        custom: true,
        required: true,
      },
    ],
    onSubmit: onSubmit,
    submitText: "Save",
    name: "create-invite-campaign-link",
  };

  return <CreateEditPage loading={false} page={page} form={form} />;
}
