import React, { ReactNode } from "react";
import { createFileRoute, useNavigate } from "@tanstack/react-router";
import { AxiosError } from "axios";
import { toast } from "sonner";
import { z } from "zod";

import { PartnerAppModel, PartnerStatus, PlatformType } from "~/api/model";
import { useGetAllPartnerAppsV1PartnerAppsGetSuspense, useUpdatePartnerAppV1PartnerAppsPartnerAppIdPut } from "~/api/partner-app/partner-app.gen";
import { getCountryOptions } from "~/helpers/country-list";
import { parseFastAPIError } from "~/helpers/parse-errors";
import { ListPage } from "~/oldComponents/layout/ListPage";
import { FormConfig, PageConfig, TableConfig } from "~/oldComponents/layout/types";
import { Select } from "~/oldComponents/select/Select";

export const Route = createFileRoute("/_auth/partner-apps/")({
  validateSearch: z.object({
    country: z.string().optional().catch(undefined),
    status: z.enum([PartnerStatus.Published, PartnerStatus.Unpublished, PartnerStatus.Deleted]).default(PartnerStatus.Published),
    platform: z.enum([PlatformType.Android, PlatformType.iOS]).optional().catch(undefined),
  }),
  component: () => <PartnerAppsPage />,
});

function PartnerAppsPage() {
  const searchParams = Route.useSearch();
  const navigate = useNavigate({ from: Route.fullPath });

  const {
    data: partnerCampaigns,
    isFetching,
    refetch,
  } = useGetAllPartnerAppsV1PartnerAppsGetSuspense({
    country: searchParams.country,
    status: searchParams.status,
    platform: searchParams.platform,
  });

  const updatePartnerAppMutation = useUpdatePartnerAppV1PartnerAppsPartnerAppIdPut({
    mutation: {
      onSuccess: () => {
        toast("Partner app campaign was successfully updated!");
        refetch();
      },
      onError: (error: AxiosError) => {
        toast.error("Error", { description: parseFastAPIError(error) as ReactNode });
      },
    },
  });

  const onStatusChange = async (e: React.ChangeEvent<HTMLSelectElement>, partnerAppCampaign: PartnerAppModel) => {
    e.preventDefault();
    await updatePartnerAppMutation.mutateAsync({
      partnerAppId: partnerAppCampaign._id as string,
      data: { ...partnerAppCampaign, status: e.currentTarget.value as PartnerStatus },
    });
  };
  const page: PageConfig = {
    title: "Partner apps",
    description: "Create new or edit current partner apps",
    addLink: "/partner-apps/create",
    tableUpload: {
      fileName: "partner-apps",
      data: partnerCampaigns,
    },
  };

  const table: TableConfig = {
    tableBuild: [
      {
        headerTitle: "Country",
        format: (item: PartnerAppModel) => item.country,
      },
      {
        headerTitle: "Title",
        format: (item: PartnerAppModel) => item.title,
      },
      {
        headerTitle: "Reward",
        format: (item: PartnerAppModel) => item.reward,
      },
      {
        headerTitle: "Downloads count",
        format: (item: PartnerAppModel) => item.downloadsCount,
      },
      {
        headerTitle: "Max downloads",
        format: (item: PartnerAppModel) => item.maximumDownloads,
      },
      {
        headerTitle: "Status",
        actionRow: true,
        format: (item: PartnerAppModel) => (
          <Select
            selectedValue={item.status as string}
            optionList={[
              {
                name: "Published",
                value: "Published",
              },
              {
                name: "Unpublished",
                value: "Unpublished",
              },
              {
                name: "Deleted",
                value: "Deleted",
              },
            ]}
            onChange={(e) => onStatusChange(e, item)}
          />
        ),
      },
    ],
    objects: partnerCampaigns,
    link: (item: PartnerAppModel) => navigate({ to: "/partner-apps/$partnerAppId", params: { partnerAppId: item._id as string } }),
    emptyMessage: "There are no partner app campaigns in the system yet.",
  };

  const onSearchSubmit = (event: React.FormEvent | undefined) => {
    event?.preventDefault();
  };

  const searchBar: FormConfig = {
    name: "partner-app-searchbar",
    fields: [
      {
        name: "country",
        label: "Country:",
        helpText: "Search by campaign country.",
        type: "search-select",
        change: (data: string) => navigate({ search: { ...searchParams, country: data ? data : undefined } }),
        value: searchParams.country,
        options: getCountryOptions(),
        empty: "All",
      },
      {
        name: "status",
        label: "Status:",
        helpText: "Search by campaign status.",
        type: "select",
        change: (e: React.ChangeEvent<HTMLSelectElement>) => navigate({ search: { ...searchParams, status: e.target.value as PartnerStatus } }),
        value: searchParams.status,
        options: [
          {
            name: "Published",
            value: PartnerStatus.Published,
          },
          {
            name: "Unpublished",
            value: PartnerStatus.Unpublished,
          },
          {
            name: "Deleted",
            value: PartnerStatus.Deleted,
          },
        ],
      },
      {
        name: "platform",
        label: "Platform:",
        helpText: "Search by availability for mobile platforms.",
        type: "select",
        change: (e: React.ChangeEvent<HTMLSelectElement>) =>
          navigate({ search: { ...searchParams, platform: e.target.value ? (e.target.value as PlatformType) : undefined } }),
        value: searchParams.platform,
        options: [
          { name: "All", value: "" },
          { name: "IOS", value: PlatformType.iOS },
          { name: "Android", value: PlatformType.Android },
        ],
      },
    ],
    onSubmit: onSearchSubmit,
    submitText: "Search",
  };

  return <ListPage form={searchBar} loading={isFetching} page={page} table={table} />;
}
