/**
 * Generated by orval
 * Do not edit manually.
 * Stepler API for the Admin
 * This is the documentation for the Stepler Admin.
 * OpenAPI spec version: 1.0.0
 */

export type UserGenders = typeof UserGenders[keyof typeof UserGenders];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UserGenders = {
  Female: 'Female',
  Male: 'Male',
  Other: 'Other',
  PreferNotToSay: 'PreferNotToSay',
} as const;
