import React from "react";
import { Link } from "@tanstack/react-router";

import { Icon } from "@/icon/icon";

import { CustomRoute, Route } from "../../routes_old";

import { AuthContext, useAuthSelector } from "~/providers/auth";

type Props = {
  menuOpened: boolean;
  route: Route | CustomRoute;
};

export const RoutedLink = ({ route, menuOpened }: Props) => {
  const selectRoles = React.useCallback((context: AuthContext | null) => context?.roles ?? [], []);
  const roles = useAuthSelector(selectRoles);

  if (!roles.includes(route.role) && route.role !== "ANY") return null;
  return (
    <Link
      to={(route as Route).to}
      className={`flex ${menuOpened ? "gap-2" : "group whitespace-nowrap"} items-center border-l-8 border-transparent bg-sidebar hover:relative hover:border-primary`}
      title={route.name}
      target={(route as Route).target}
      onClick={(route as CustomRoute).onClick}
    >
      {route.icon && (
        <div className="px-4 py-5">
          <Icon icon={route.icon} />
        </div>
      )}

      <div className={`bg-sidebar ${menuOpened ? "py-4 pr-4" : "hidden p-4 group-hover:block"} text-sm`}>{route.name}</div>
    </Link>
  );
};
