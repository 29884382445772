import React from "react";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa6";

import Button from "../button/Button";
import { PaginationConfig } from "../layout/types";
import { Select } from "../select/Select";

import "./paginationcontrols.css";

type Props = PaginationConfig;

export const PaginationControls = ({ page, limit, hasNext, hasPrevious, total }: Props) => {
  const onPrevious = () => {
    if (page.value > 1) {
      const newPage = page.value - 1;
      page.onChange(newPage);
    }
  };
  const onNext = () => {
    if (page.value <= total) {
      const newPage = page.value + 1;
      page.onChange(newPage);
    }
  };

  const onLimitChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    page.onChange(1);
    const newLimit = Number(e.target.value);
    limit.onChange(newLimit);
  };

  const totalPages = total && limit ? Math.ceil(total / limit.value) : 0;

  return (
    <section className="pagination_section">
      <div className="pagination__actions">
        {hasPrevious}
        <Button disabled={hasPrevious ? undefined : true} onClick={onPrevious} styleType="secondary" size="small">
          <FaChevronLeft />
          Previous
        </Button>
        {hasNext}
        <Button disabled={hasNext ? undefined : true} onClick={onNext} styleType="secondary" size="small">
          Next
          <FaChevronRight />
        </Button>
        <span className="pagination_section-labels">
          Page {page.value} of {totalPages}
        </span>
      </div>
      <div className="pagination_section-limit-wrapper">
        <span className="pagination_section-labels">Show:</span>
        <Select
          selectedValue={limit.value || 100}
          onChange={onLimitChange}
          optionList={[
            { name: "1", value: 1 },
            { name: "10", value: 10 },
            { name: "100", value: 100 },
            { name: "200", value: 200 },
            { name: "500", value: 500 },
          ]}
        />
      </div>
    </section>
  );
};
