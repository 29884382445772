import React from "react";

import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/ui/select";

import { getBackendUrl, getBackendUrls } from "~/helpers/backend";
import { useAuthSelector } from "~/providers/auth";

export function BackendSelector({ className }: { className?: string }) {
  const logout = useAuthSelector((context) => {
    if (!context) {
      console.error(
        "[BackendSelector] Auth context is not available. This might indicate a component rendering outside AuthProvider."
      );
      return () => {};
    }
    return context.logout;
  });

  const backendOptions = getBackendUrls();
  const backend = getBackendUrl();

  const setBackend = (backend: string) => {
    localStorage.setItem("backend", backend);
    logout();
  };

  if (backendOptions.length <= 1) return null;

  return (
    <div className={className}>
      <Select defaultValue={backend} onValueChange={setBackend}>
        <SelectTrigger>
          <SelectValue placeholder="Pick the backend to talk to" />
        </SelectTrigger>
        <SelectContent>
          {backendOptions.map((option: string) => (
            <SelectItem key={option} value={option}>
              {option}
            </SelectItem>
          ))}
        </SelectContent>
      </Select>
    </div>
  );
}
