import React from "react";

import { Form } from "../form/Form";
import { PaginationControls } from "../pagination-controls/PaginationControls";
import { OldTable } from "../table/Table";

import PageLayout from "./PageLayout";
import { FormConfig, PageConfig, PaginationConfig, TableConfig, TabsConfig } from "./types";

import { ErrorType } from "~/api/base";
import { Error400Response, HTTPValidationError } from "~/api/model";

type Props = {
  loading: boolean;
  page: PageConfig;
  form?: FormConfig;
  table: TableConfig;
  readonly?: boolean;
  pagination?: PaginationConfig;
  tabs?: TabsConfig;
  children?: React.ReactNode;
  error?: ErrorType<Error400Response | HTTPValidationError> | null;
};

export const ListPage = ({ page, form, table, pagination, tabs, loading, readonly, children, error }: Props) => {
  return (
    <PageLayout
      title={page.title}
      description={page.description}
      addLink={page.addLink}
      additionalLink={page.additionalLink}
      additionalAddContent={page.additionalAddContent}
      loading={loading}
      tableUpload={page.tableUpload}
      addContent={page.addContent}
      readonly={readonly}
      onDeleteClick={page.onDelete}
      onDeleteText={page.onDeleteText}
      tabs={tabs}
    >
      {form && (
        <Form name={form.name} fields={form.fields} onSubmit={form.onSubmit} submitText={form.submitText} showStayOnPage={form.showStayOnPage} />
      )}
      {children}
      <OldTable
        tableConfig={table.tableBuild}
        objects={table.objects}
        link={table.link}
        emptyMessage={error ? "There was an error loading the data" : table.emptyMessage}
        tableTitle={table.tableTitle}
        tableStyles={table.tableStyles}
        id={table.id}
      />
      {pagination && (
        <PaginationControls
          page={pagination.page}
          limit={pagination.limit}
          total={pagination.total}
          hasNext={pagination.hasNext}
          hasPrevious={pagination.hasPrevious}
        />
      )}
    </PageLayout>
  );
};
