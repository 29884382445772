import React from "react";
import { useNavigate, useRouterState } from "@tanstack/react-router";
import { useContextSelector } from "use-context-selector"; // Import useContextSelector

import { Combobox, ComboboxOption } from "@/combobox";
import { Icon } from "@/icon/icon";
import { Button } from "@/ui/button";

import steplerIcon from "~/assets/icons/stepler-logo.png";
import { CountriesContext, Country } from "~/context/context";

type Props = {
  onMenuChange: () => void;
};

const Header = ({ onMenuChange }: Props) => {
  const router = useRouterState();
  const params = router.matches[router.matches.length - 1].params as Record<"market", string>;

  // Use useContextSelector to get only the 'countries' part of the context
  const countries: Country[] = useContextSelector(CountriesContext, (context) => context.countries);

  const navigate = useNavigate();

  // Use useCallback to memoize the setSelectedCountry function
  const setSelectedCountry = React.useCallback(
    (value: string) => {
      navigate({
        to: router.matches.at(-1)?.fullPath,
        params: { market: value },
      });
    },
    [navigate, router.matches],
  );

  return (
    <div className="flex h-16 items-center justify-between bg-header px-2.5 py-4 drop-shadow-sm">
      <section className="flex items-center gap-4">
        <Button variant="ghost" size="icon" onClick={onMenuChange}>
          <Icon icon="Menu" className="h-8 w-8" />
        </Button>
        <img src={steplerIcon} className="h-8" />
        <div className="rounded-md bg-purple-600 p-2 capitalize text-white">{import.meta.env.VITE_REACT_APP_NODE_ENV}</div>
      </section>
      <section>
        {params.market && (
          <Combobox
            searchName="Country"
            placeholder="Country"
            options={countries || []}
            onChange={(value: ComboboxOption) => {
              if (value.value) {
                setSelectedCountry(value.value);
              }
            }}
            value={params.market}
            showFlag={true}
          />
        )}
      </section>
    </div>
  );
};

export default Header;
