import React from "react";

import Link from "../../oldComponents/button/Link";
import Gears from "../../oldComponents/loader/gears/Gears";

import "./error.css";

const ErrorPage = () => {
  return (
    <section className="error-page_wrapper">
      <section className="error-page_headers">
        <h1 className="error-page_headers-title">There was a problem</h1>
        <p className="error-page_headers-subtitle">An unhanded error has occurred. Please try again later or contact support.</p>
      </section>
      <Gears />
      <section className="error-page_actions">
        <Link to={"/"}>Go to the homepage</Link>
      </section>
    </section>
  );
};

export default ErrorPage;
