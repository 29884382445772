import React from "react";
import { memo } from "react";

import "./tabs.scss";

type Props = {
  tabs: { display: string; key: string }[];
  activeTab: string;
  onClick: (tab: string) => void;
};

export const Tabs = memo(({ tabs, activeTab, onClick }: Props) => {
  return (
    <ul role="list" className="flex gap-2">
      {tabs.map(({ display, key }) => {
        return (
          <li
            key={key}
            className="cursor-pointer rounded-t-lg bg-tab px-10 py-3 opacity-50 hover:opacity-70 data-[active]:opacity-100"
            data-active={key === activeTab ? true : undefined}
            onClick={onClick.bind(onClick, key)}
          >
            {display}
          </li>
        );
      })}
    </ul>
  );
});
Tabs.displayName = "Tabs";
