import { toast as sonnerToast } from "sonner";

import { ImageType } from "~/api/model";

type AllowedDimension = {
  aspectRatio: number;
  aspectRatioString: string;
  minWidth: number;
  minHeight: number;
};

type AllowedDimensions = {
  offer_banner: AllowedDimension;
  offer_thumbnail: AllowedDimension;
  offer_logo: AllowedDimension;
  recommended_image: AllowedDimension;
  invite_campaign_thumbnail: AllowedDimension;
  invite_campaign_banner: AllowedDimension;
  market_banner: AllowedDimension;
  partner_app_logo: AllowedDimension;
  partner_app_image: AllowedDimension;
  step_campaign_logo: AllowedDimension;
};

const allowedDimensions: AllowedDimensions = {
  offer_banner: { aspectRatio: 1, aspectRatioString: "1:1", minWidth: 800, minHeight: 800 },
  offer_thumbnail: { aspectRatio: 16 / 9, aspectRatioString: "16:9", minWidth: 800, minHeight: 450 },
  offer_logo: { aspectRatio: 1, aspectRatioString: "1:1", minWidth: 300, minHeight: 300 },
  recommended_image: { aspectRatio: 16 / 9, aspectRatioString: "16:9", minWidth: 800, minHeight: 450 },
  invite_campaign_thumbnail: { aspectRatio: 16 / 9, aspectRatioString: "16:9", minWidth: 800, minHeight: 450 },
  invite_campaign_banner: { aspectRatio: 16 / 9, aspectRatioString: "16:9", minWidth: 800, minHeight: 450 },
  market_banner: { aspectRatio: 16 / 9, aspectRatioString: "16:9", minWidth: 800, minHeight: 450 },
  partner_app_logo: { aspectRatio: 1, aspectRatioString: "1:1", minWidth: 300, minHeight: 300 },
  partner_app_image: { aspectRatio: 1, aspectRatioString: "1:1", minWidth: 800, minHeight: 800 },
  step_campaign_logo: { aspectRatio: 1, aspectRatioString: "1:1", minWidth: 300, minHeight: 300 },
};

function getAspectRatioFormat(width: number, height: number) {
  // Check for invalid inputs
  if (width <= 0 || height <= 0) {
    return "Invalid dimensions";
  }

  // Calculate greatest common divisor (GCD) for cleaner ratio representation
  function gcd(a: number, b: number): number {
    return b === 0 ? a : gcd(b, a % b);
  }

  const divisor = gcd(width, height);

  // Simplify width and height by GCD
  const simplifiedWidth = width / divisor;
  const simplifiedHeight = height / divisor;

  return `${simplifiedWidth}:${simplifiedHeight}`;
}

export const validateImageDimensions = (imageType: ImageType, file: Blob, toast: typeof sonnerToast) => {
  const dimensions = allowedDimensions[imageType];

  return new Promise((resolve, reject) => {
    const img = new Image();
    img.src = URL.createObjectURL(file as Blob);
    img.onload = () => {
      const { width, height } = img;
      const aspectRatio = width / height;
      // Check that file is of type Blob
      if (file && file.size > 5000000) {
        toast.error("Error", { description: `File size exceeds 5000000 Bytes limit. It currently is ${file.size} Bytes` });
        reject(false);
      }
      if (aspectRatio.toFixed(2) !== dimensions.aspectRatio.toFixed(2)) {
        toast.error("Error", {
          description: `Invalid aspect ratio for ${imageType}. Expected ${dimensions.aspectRatioString} Gotten ${getAspectRatioFormat(width, height)}`,
        });
        reject(false);
      } else if (width < dimensions.minWidth || height < dimensions.minHeight) {
        toast.error("Error", {
          description: `Invalid dimensions for ${imageType}. Minimum dimensions: ${dimensions.minWidth}x${dimensions.minHeight}. Gotten: ${width}x${height}`,
        });
        reject(false);
      } else {
        resolve(true);
      }
    };
  });
};
