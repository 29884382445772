import React, { Suspense, useEffect, useMemo } from "react";

import { CountriesContext } from "../context/context";
import { CountryList } from "../helpers/country-list";

import { useAuthSelector } from "./auth";

import { AXIOS_INSTANCE } from "~/api/base";
import { useGetAvailableCountriesV1MarketConfigAvailableCountriesGet } from "~/api/market-config/market-config.gen";

type Props = {
  children?: React.ReactNode;
};

const CountriesProvider = ({ children }: Props) => {
  return (
    <Suspense fallback={<div></div>}>
      <CountriesContent>{children}</CountriesContent>
    </Suspense>
  );
};

const CountriesContent = ({ children }: Props) => {
  const accessToken = useAuthSelector((context) => context?.accessToken);

  useEffect(() => {
    if (accessToken) {
      AXIOS_INSTANCE.defaults.headers.Authorization = `Bearer ${accessToken}`;
    } else {
      AXIOS_INSTANCE.defaults.headers.Authorization = "";
    }
  }, [accessToken]);

  const { data: countries } = useGetAvailableCountriesV1MarketConfigAvailableCountriesGet({
    query: {
      enabled: !!accessToken,
      initialData: [],
    },
  });

  const countryMap = useMemo(() => {
    return CountryList.reduce(
      (acc, country) => {
        acc[country.code] = country.name;
        return acc;
      },
      {} as { [key: string]: string },
    );
  }, []);

  const value = useMemo(
    () => ({
      countries:
        countries
          ?.sort((a, b) => a.localeCompare(b))
          .map((countryCode: string) => {
            const countryName = countryMap[countryCode];
            if (!countryName) {
              console.warn(`Country code ${countryCode} not found in CountryList`);
            }
            return {
              name: countryName ?? countryCode,
              label: countryName ?? countryCode,
              value: countryCode,
            };
          }) || [],
    }),
    [countries],
  );

  return <CountriesContext.Provider value={value}>{children}</CountriesContext.Provider>;
};

export default CountriesProvider;
