/**
 * Generated by orval
 * Do not edit manually.
 * Stepler API for the Admin
 * This is the documentation for the Stepler Admin.
 * OpenAPI spec version: 1.0.0
 */

export type BusinessRuleType = typeof BusinessRuleType[keyof typeof BusinessRuleType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const BusinessRuleType = {
  NewUserSpecial: 'NewUserSpecial',
  MostSold: 'MostSold',
  All: 'All',
} as const;
