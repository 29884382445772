import axios from "axios";

import { getBackendUrl } from "~/helpers/backend";

const axiosRefreshInstance = axios.create({
  baseURL: getBackendUrl(),
});

type RefreshRequest = {
  refreshToken: string;
  userId: string;
};

export async function sendLoginEmail({ email, login_url }: { email: string; login_url: string }) {
  const res = await axiosRefreshInstance.post("/v1/auth/send_login_token/", { email: email, login_url: login_url });
  return res.data;
}

export async function refreshTokenCall({ refreshToken, userId }: RefreshRequest) {
  const res = await axiosRefreshInstance.post("/v1/auth/refresh/", {
    old_refresh_token: refreshToken,
    user_id: userId,
  });
  return res.data;
}
