/**
 * Generated by orval
 * Do not edit manually.
 * Stepler API for the Admin
 * This is the documentation for the Stepler Admin.
 * OpenAPI spec version: 1.0.0
 */
import {
  useMutation,
  useQuery,
  useSuspenseQuery
} from '@tanstack/react-query'
import type {
  DefinedInitialDataOptions,
  DefinedUseQueryResult,
  MutationFunction,
  QueryFunction,
  QueryKey,
  UndefinedInitialDataOptions,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
  UseSuspenseQueryOptions,
  UseSuspenseQueryResult
} from '@tanstack/react-query'
import type {
  Error400Response,
  GetAllInviteCampaignsV1InviteCampaignsGetParams,
  HTTPValidationError,
  InviteCampaignModel,
  InviteCampaignPatch
} from '.././model'
import { axiosInstance } from '.././base';
import type { ErrorType, BodyType } from '.././base';


type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];


/**
 * Get all invite campaigns that are in the system
 * @summary Get All Invite Campaigns
 */
export const getAllInviteCampaignsV1InviteCampaignsGet = (
    params?: GetAllInviteCampaignsV1InviteCampaignsGetParams,
 options?: SecondParameter<typeof axiosInstance>,signal?: AbortSignal
) => {
      
      
      return axiosInstance<InviteCampaignModel[]>(
      {url: `/v1/invite_campaigns/`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetAllInviteCampaignsV1InviteCampaignsGetQueryKey = (params?: GetAllInviteCampaignsV1InviteCampaignsGetParams,) => {
    return [`/v1/invite_campaigns/`, ...(params ? [params]: [])] as const;
    }

    
export const getGetAllInviteCampaignsV1InviteCampaignsGetQueryOptions = <TData = Awaited<ReturnType<typeof getAllInviteCampaignsV1InviteCampaignsGet>>, TError = ErrorType<HTTPValidationError>>(params?: GetAllInviteCampaignsV1InviteCampaignsGetParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getAllInviteCampaignsV1InviteCampaignsGet>>, TError, TData>>, request?: SecondParameter<typeof axiosInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetAllInviteCampaignsV1InviteCampaignsGetQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getAllInviteCampaignsV1InviteCampaignsGet>>> = ({ signal }) => getAllInviteCampaignsV1InviteCampaignsGet(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getAllInviteCampaignsV1InviteCampaignsGet>>, TError, TData> & { queryKey: QueryKey }
}

export type GetAllInviteCampaignsV1InviteCampaignsGetQueryResult = NonNullable<Awaited<ReturnType<typeof getAllInviteCampaignsV1InviteCampaignsGet>>>
export type GetAllInviteCampaignsV1InviteCampaignsGetQueryError = ErrorType<HTTPValidationError>


export function useGetAllInviteCampaignsV1InviteCampaignsGet<TData = Awaited<ReturnType<typeof getAllInviteCampaignsV1InviteCampaignsGet>>, TError = ErrorType<HTTPValidationError>>(
 params: undefined |  GetAllInviteCampaignsV1InviteCampaignsGetParams, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getAllInviteCampaignsV1InviteCampaignsGet>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getAllInviteCampaignsV1InviteCampaignsGet>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof axiosInstance>}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetAllInviteCampaignsV1InviteCampaignsGet<TData = Awaited<ReturnType<typeof getAllInviteCampaignsV1InviteCampaignsGet>>, TError = ErrorType<HTTPValidationError>>(
 params?: GetAllInviteCampaignsV1InviteCampaignsGetParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getAllInviteCampaignsV1InviteCampaignsGet>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getAllInviteCampaignsV1InviteCampaignsGet>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof axiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetAllInviteCampaignsV1InviteCampaignsGet<TData = Awaited<ReturnType<typeof getAllInviteCampaignsV1InviteCampaignsGet>>, TError = ErrorType<HTTPValidationError>>(
 params?: GetAllInviteCampaignsV1InviteCampaignsGetParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getAllInviteCampaignsV1InviteCampaignsGet>>, TError, TData>>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
/**
 * @summary Get All Invite Campaigns
 */

export function useGetAllInviteCampaignsV1InviteCampaignsGet<TData = Awaited<ReturnType<typeof getAllInviteCampaignsV1InviteCampaignsGet>>, TError = ErrorType<HTTPValidationError>>(
 params?: GetAllInviteCampaignsV1InviteCampaignsGetParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getAllInviteCampaignsV1InviteCampaignsGet>>, TError, TData>>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetAllInviteCampaignsV1InviteCampaignsGetQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetAllInviteCampaignsV1InviteCampaignsGetSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof getAllInviteCampaignsV1InviteCampaignsGet>>, TError = ErrorType<HTTPValidationError>>(params?: GetAllInviteCampaignsV1InviteCampaignsGetParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getAllInviteCampaignsV1InviteCampaignsGet>>, TError, TData>>, request?: SecondParameter<typeof axiosInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetAllInviteCampaignsV1InviteCampaignsGetQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getAllInviteCampaignsV1InviteCampaignsGet>>> = ({ signal }) => getAllInviteCampaignsV1InviteCampaignsGet(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof getAllInviteCampaignsV1InviteCampaignsGet>>, TError, TData> & { queryKey: QueryKey }
}

export type GetAllInviteCampaignsV1InviteCampaignsGetSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof getAllInviteCampaignsV1InviteCampaignsGet>>>
export type GetAllInviteCampaignsV1InviteCampaignsGetSuspenseQueryError = ErrorType<HTTPValidationError>


export function useGetAllInviteCampaignsV1InviteCampaignsGetSuspense<TData = Awaited<ReturnType<typeof getAllInviteCampaignsV1InviteCampaignsGet>>, TError = ErrorType<HTTPValidationError>>(
 params: undefined |  GetAllInviteCampaignsV1InviteCampaignsGetParams, options: { query:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getAllInviteCampaignsV1InviteCampaignsGet>>, TError, TData>>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetAllInviteCampaignsV1InviteCampaignsGetSuspense<TData = Awaited<ReturnType<typeof getAllInviteCampaignsV1InviteCampaignsGet>>, TError = ErrorType<HTTPValidationError>>(
 params?: GetAllInviteCampaignsV1InviteCampaignsGetParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getAllInviteCampaignsV1InviteCampaignsGet>>, TError, TData>>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetAllInviteCampaignsV1InviteCampaignsGetSuspense<TData = Awaited<ReturnType<typeof getAllInviteCampaignsV1InviteCampaignsGet>>, TError = ErrorType<HTTPValidationError>>(
 params?: GetAllInviteCampaignsV1InviteCampaignsGetParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getAllInviteCampaignsV1InviteCampaignsGet>>, TError, TData>>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
/**
 * @summary Get All Invite Campaigns
 */

export function useGetAllInviteCampaignsV1InviteCampaignsGetSuspense<TData = Awaited<ReturnType<typeof getAllInviteCampaignsV1InviteCampaignsGet>>, TError = ErrorType<HTTPValidationError>>(
 params?: GetAllInviteCampaignsV1InviteCampaignsGetParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getAllInviteCampaignsV1InviteCampaignsGet>>, TError, TData>>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetAllInviteCampaignsV1InviteCampaignsGetSuspenseQueryOptions(params,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * Create a new invite campaign
 * @summary Create Invite Campaign
 */
export const createInviteCampaignV1InviteCampaignsPost = (
    inviteCampaignModel: BodyType<InviteCampaignModel>,
 options?: SecondParameter<typeof axiosInstance>,) => {
      
      
      return axiosInstance<InviteCampaignModel>(
      {url: `/v1/invite_campaigns/`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: inviteCampaignModel
    },
      options);
    }
  


export const getCreateInviteCampaignV1InviteCampaignsPostMutationOptions = <TError = ErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createInviteCampaignV1InviteCampaignsPost>>, TError,{data: BodyType<InviteCampaignModel>}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof createInviteCampaignV1InviteCampaignsPost>>, TError,{data: BodyType<InviteCampaignModel>}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createInviteCampaignV1InviteCampaignsPost>>, {data: BodyType<InviteCampaignModel>}> = (props) => {
          const {data} = props ?? {};

          return  createInviteCampaignV1InviteCampaignsPost(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type CreateInviteCampaignV1InviteCampaignsPostMutationResult = NonNullable<Awaited<ReturnType<typeof createInviteCampaignV1InviteCampaignsPost>>>
    export type CreateInviteCampaignV1InviteCampaignsPostMutationBody = BodyType<InviteCampaignModel>
    export type CreateInviteCampaignV1InviteCampaignsPostMutationError = ErrorType<HTTPValidationError>

    /**
 * @summary Create Invite Campaign
 */
export const useCreateInviteCampaignV1InviteCampaignsPost = <TError = ErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createInviteCampaignV1InviteCampaignsPost>>, TError,{data: BodyType<InviteCampaignModel>}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof createInviteCampaignV1InviteCampaignsPost>>,
        TError,
        {data: BodyType<InviteCampaignModel>},
        TContext
      > => {

      const mutationOptions = getCreateInviteCampaignV1InviteCampaignsPostMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Get invite campaign by id
 * @summary Get Invite Campaign By Id
 */
export const getInviteCampaignByIdV1InviteCampaignsInviteCampaignIdGet = (
    inviteCampaignId: string,
 options?: SecondParameter<typeof axiosInstance>,signal?: AbortSignal
) => {
      
      
      return axiosInstance<InviteCampaignModel>(
      {url: `/v1/invite_campaigns/${inviteCampaignId}/`, method: 'GET', signal
    },
      options);
    }
  

export const getGetInviteCampaignByIdV1InviteCampaignsInviteCampaignIdGetQueryKey = (inviteCampaignId: string,) => {
    return [`/v1/invite_campaigns/${inviteCampaignId}/`] as const;
    }

    
export const getGetInviteCampaignByIdV1InviteCampaignsInviteCampaignIdGetQueryOptions = <TData = Awaited<ReturnType<typeof getInviteCampaignByIdV1InviteCampaignsInviteCampaignIdGet>>, TError = ErrorType<Error400Response | HTTPValidationError>>(inviteCampaignId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getInviteCampaignByIdV1InviteCampaignsInviteCampaignIdGet>>, TError, TData>>, request?: SecondParameter<typeof axiosInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetInviteCampaignByIdV1InviteCampaignsInviteCampaignIdGetQueryKey(inviteCampaignId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getInviteCampaignByIdV1InviteCampaignsInviteCampaignIdGet>>> = ({ signal }) => getInviteCampaignByIdV1InviteCampaignsInviteCampaignIdGet(inviteCampaignId, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(inviteCampaignId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getInviteCampaignByIdV1InviteCampaignsInviteCampaignIdGet>>, TError, TData> & { queryKey: QueryKey }
}

export type GetInviteCampaignByIdV1InviteCampaignsInviteCampaignIdGetQueryResult = NonNullable<Awaited<ReturnType<typeof getInviteCampaignByIdV1InviteCampaignsInviteCampaignIdGet>>>
export type GetInviteCampaignByIdV1InviteCampaignsInviteCampaignIdGetQueryError = ErrorType<Error400Response | HTTPValidationError>


export function useGetInviteCampaignByIdV1InviteCampaignsInviteCampaignIdGet<TData = Awaited<ReturnType<typeof getInviteCampaignByIdV1InviteCampaignsInviteCampaignIdGet>>, TError = ErrorType<Error400Response | HTTPValidationError>>(
 inviteCampaignId: string, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getInviteCampaignByIdV1InviteCampaignsInviteCampaignIdGet>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getInviteCampaignByIdV1InviteCampaignsInviteCampaignIdGet>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof axiosInstance>}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetInviteCampaignByIdV1InviteCampaignsInviteCampaignIdGet<TData = Awaited<ReturnType<typeof getInviteCampaignByIdV1InviteCampaignsInviteCampaignIdGet>>, TError = ErrorType<Error400Response | HTTPValidationError>>(
 inviteCampaignId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getInviteCampaignByIdV1InviteCampaignsInviteCampaignIdGet>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getInviteCampaignByIdV1InviteCampaignsInviteCampaignIdGet>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof axiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetInviteCampaignByIdV1InviteCampaignsInviteCampaignIdGet<TData = Awaited<ReturnType<typeof getInviteCampaignByIdV1InviteCampaignsInviteCampaignIdGet>>, TError = ErrorType<Error400Response | HTTPValidationError>>(
 inviteCampaignId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getInviteCampaignByIdV1InviteCampaignsInviteCampaignIdGet>>, TError, TData>>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
/**
 * @summary Get Invite Campaign By Id
 */

export function useGetInviteCampaignByIdV1InviteCampaignsInviteCampaignIdGet<TData = Awaited<ReturnType<typeof getInviteCampaignByIdV1InviteCampaignsInviteCampaignIdGet>>, TError = ErrorType<Error400Response | HTTPValidationError>>(
 inviteCampaignId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getInviteCampaignByIdV1InviteCampaignsInviteCampaignIdGet>>, TError, TData>>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetInviteCampaignByIdV1InviteCampaignsInviteCampaignIdGetQueryOptions(inviteCampaignId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetInviteCampaignByIdV1InviteCampaignsInviteCampaignIdGetSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof getInviteCampaignByIdV1InviteCampaignsInviteCampaignIdGet>>, TError = ErrorType<Error400Response | HTTPValidationError>>(inviteCampaignId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getInviteCampaignByIdV1InviteCampaignsInviteCampaignIdGet>>, TError, TData>>, request?: SecondParameter<typeof axiosInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetInviteCampaignByIdV1InviteCampaignsInviteCampaignIdGetQueryKey(inviteCampaignId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getInviteCampaignByIdV1InviteCampaignsInviteCampaignIdGet>>> = ({ signal }) => getInviteCampaignByIdV1InviteCampaignsInviteCampaignIdGet(inviteCampaignId, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(inviteCampaignId), ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof getInviteCampaignByIdV1InviteCampaignsInviteCampaignIdGet>>, TError, TData> & { queryKey: QueryKey }
}

export type GetInviteCampaignByIdV1InviteCampaignsInviteCampaignIdGetSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof getInviteCampaignByIdV1InviteCampaignsInviteCampaignIdGet>>>
export type GetInviteCampaignByIdV1InviteCampaignsInviteCampaignIdGetSuspenseQueryError = ErrorType<Error400Response | HTTPValidationError>


export function useGetInviteCampaignByIdV1InviteCampaignsInviteCampaignIdGetSuspense<TData = Awaited<ReturnType<typeof getInviteCampaignByIdV1InviteCampaignsInviteCampaignIdGet>>, TError = ErrorType<Error400Response | HTTPValidationError>>(
 inviteCampaignId: string, options: { query:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getInviteCampaignByIdV1InviteCampaignsInviteCampaignIdGet>>, TError, TData>>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetInviteCampaignByIdV1InviteCampaignsInviteCampaignIdGetSuspense<TData = Awaited<ReturnType<typeof getInviteCampaignByIdV1InviteCampaignsInviteCampaignIdGet>>, TError = ErrorType<Error400Response | HTTPValidationError>>(
 inviteCampaignId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getInviteCampaignByIdV1InviteCampaignsInviteCampaignIdGet>>, TError, TData>>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetInviteCampaignByIdV1InviteCampaignsInviteCampaignIdGetSuspense<TData = Awaited<ReturnType<typeof getInviteCampaignByIdV1InviteCampaignsInviteCampaignIdGet>>, TError = ErrorType<Error400Response | HTTPValidationError>>(
 inviteCampaignId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getInviteCampaignByIdV1InviteCampaignsInviteCampaignIdGet>>, TError, TData>>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey }
/**
 * @summary Get Invite Campaign By Id
 */

export function useGetInviteCampaignByIdV1InviteCampaignsInviteCampaignIdGetSuspense<TData = Awaited<ReturnType<typeof getInviteCampaignByIdV1InviteCampaignsInviteCampaignIdGet>>, TError = ErrorType<Error400Response | HTTPValidationError>>(
 inviteCampaignId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getInviteCampaignByIdV1InviteCampaignsInviteCampaignIdGet>>, TError, TData>>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetInviteCampaignByIdV1InviteCampaignsInviteCampaignIdGetSuspenseQueryOptions(inviteCampaignId,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * Update invite campaign by id
 * @summary Update Invite Campaign
 */
export const updateInviteCampaignV1InviteCampaignsInviteCampaignIdPatch = (
    inviteCampaignId: string,
    inviteCampaignPatch: BodyType<InviteCampaignPatch>,
 options?: SecondParameter<typeof axiosInstance>,) => {
      
      
      return axiosInstance<InviteCampaignModel>(
      {url: `/v1/invite_campaigns/${inviteCampaignId}/`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: inviteCampaignPatch
    },
      options);
    }
  


export const getUpdateInviteCampaignV1InviteCampaignsInviteCampaignIdPatchMutationOptions = <TError = ErrorType<Error400Response | HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateInviteCampaignV1InviteCampaignsInviteCampaignIdPatch>>, TError,{inviteCampaignId: string;data: BodyType<InviteCampaignPatch>}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof updateInviteCampaignV1InviteCampaignsInviteCampaignIdPatch>>, TError,{inviteCampaignId: string;data: BodyType<InviteCampaignPatch>}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateInviteCampaignV1InviteCampaignsInviteCampaignIdPatch>>, {inviteCampaignId: string;data: BodyType<InviteCampaignPatch>}> = (props) => {
          const {inviteCampaignId,data} = props ?? {};

          return  updateInviteCampaignV1InviteCampaignsInviteCampaignIdPatch(inviteCampaignId,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type UpdateInviteCampaignV1InviteCampaignsInviteCampaignIdPatchMutationResult = NonNullable<Awaited<ReturnType<typeof updateInviteCampaignV1InviteCampaignsInviteCampaignIdPatch>>>
    export type UpdateInviteCampaignV1InviteCampaignsInviteCampaignIdPatchMutationBody = BodyType<InviteCampaignPatch>
    export type UpdateInviteCampaignV1InviteCampaignsInviteCampaignIdPatchMutationError = ErrorType<Error400Response | HTTPValidationError>

    /**
 * @summary Update Invite Campaign
 */
export const useUpdateInviteCampaignV1InviteCampaignsInviteCampaignIdPatch = <TError = ErrorType<Error400Response | HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateInviteCampaignV1InviteCampaignsInviteCampaignIdPatch>>, TError,{inviteCampaignId: string;data: BodyType<InviteCampaignPatch>}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof updateInviteCampaignV1InviteCampaignsInviteCampaignIdPatch>>,
        TError,
        {inviteCampaignId: string;data: BodyType<InviteCampaignPatch>},
        TContext
      > => {

      const mutationOptions = getUpdateInviteCampaignV1InviteCampaignsInviteCampaignIdPatchMutationOptions(options);

      return useMutation(mutationOptions);
    }
    