/* eslint-disable @typescript-eslint/no-explicit-any */
export function flattenNestedObjects(arr: any[]): any[] {
  if (!Array.isArray(arr)) return [];
  const result: any[] = [];

  for (const obj of arr) {
    const flattenedObj: any = {};

    const flatten = (data: any, prefix = "") => {
      if (Array.isArray(data)) {
        for (let i = 0; i < data.length; i++) {
          const value = data[i];
          const key = `${prefix}${i}`;
          flatten(value, `${key}.`);
        }
      } else if (typeof data === "object" && data !== null) {
        for (const key in data) {
          const value = data[key];
          const newKey = `${prefix}${key}`;
          flatten(value, `${newKey}.`);
        }
      } else {
        flattenedObj[prefix.slice(0, -1)] = data;
      }
    };

    flatten(obj);

    result.push(flattenedObj);
  }

  return result;
}
