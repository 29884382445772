import React, { ReactNode } from "react";
import { useEffect, useMemo, useState } from "react";
import { CSVLink } from "react-csv";
import { useQueryClient, useSuspenseQuery } from "@tanstack/react-query";
import { createFileRoute, Link, useNavigate } from "@tanstack/react-router";
import { ColumnDef, getCoreRowModel, getFilteredRowModel, getPaginationRowModel, useReactTable } from "@tanstack/react-table";
import { AxiosError } from "axios";
import csvToJson from "csvtojson";
import { toast } from "sonner";
import { z } from "zod";

import { ConfirmDialog } from "@/confirm_dialog";
import { DataTable } from "@/datatable";
import { Icon } from "@/icon/icon";
import { Button, buttonVariants } from "@/ui/button";
import { Card, CardContent } from "@/ui/card";
import { Checkbox } from "@/ui/checkbox";
import { Input } from "@/ui/input";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/ui/select";

import {
  useBulkDeleteCouponCodesV1CouponCodesBulkDeleteDelete,
  useCreateCouponCodesV1CouponCodesPost,
  useGetCouponCodesV1CouponCodesGet,
  useUpdateCouponCodeV1CouponCodesCouponCodeIdPatch,
} from "~/api/coupon-codes/coupon-codes.gen";
import { CouponCodeModel, CouponCodeStatus } from "~/api/model";
import { getGetOfferByIdV1OffersOfferIdGetSuspenseQueryOptions } from "~/api/offers/offers.gen";
import { parseFastAPIError } from "~/helpers/parse-errors";
import SplashPage from "~/pages/Splash/Splash";
import { useMarketplace } from "~/providers/marketplace";

export type Code = {
  id?: string;
  code?: string;
  url?: string;
  status: string;
  order_id?: number;
};

export type ToUpload = {
  code: string | null;
  url: string | null;
  offerId: string;
};

export const Route = createFileRoute("/_auth/$market/marketplace/rewards/$rewardId/coupons")({
  validateSearch: z.object({
    status: z.string().default("Unused"),
  }),
  loader: ({ context: { queryClient }, params: { rewardId } }) => {
    return queryClient.ensureQueryData(getGetOfferByIdV1OffersOfferIdGetSuspenseQueryOptions(rewardId));
  },
  component: () => <CouponCodesPage />,
});

function CouponCodesPage() {
  const navigate = useNavigate();
  const search = Route.useSearch();
  const queryClient = useQueryClient();

  const rewardId = Route.useParams().rewardId;
  const market = Route.useParams().market;
  const { data: offer, status: offerStatus } = useSuspenseQuery(getGetOfferByIdV1OffersOfferIdGetSuspenseQueryOptions(rewardId));
  const [mappedCodes, setMappedCodes] = useState<Code[]>([]);
  const [rowSelection, setRowSelection] = useState({});
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);

  const [toUpload, setToUpload] = useState<ToUpload[]>([]);
  const [uploading, setUploading] = useState(false);

  const { refetchMarketplace } = useMarketplace();

  const {
    data: coupons,
    isFetching,
    refetch: visibleCouponsRefetch,
    isLoading,
  } = useGetCouponCodesV1CouponCodesGet(
    {
      offer_id: rewardId,
      status: search.status,
    },
    {
      query: {
        enabled: offerStatus === "success",
        initialData: [],
      },
    },
  );

  const { data: unusedCoupons, refetch } = useGetCouponCodesV1CouponCodesGet(
    {
      offer_id: rewardId,
      status: "Unused",
    },
    {
      query: {
        enabled: offerStatus === "success",
        initialData: [],
      },
    },
  );

  const uploadCouponsMutation = useCreateCouponCodesV1CouponCodesPost({
    mutation: {
      onError: (error: AxiosError) => {
        setUploading(false);
        toast.error("Error", { description: parseFastAPIError(error) as ReactNode });
      },
      onSuccess: () => {
        setUploading(false);
        toast("Coupon codes were successfully uploaded!");
        setTimeout(() => {
          queryClient.invalidateQueries({ queryKey: ["/v1/coupons/"] });
          queryClient.invalidateQueries({ queryKey: ["/v1/marketplace/"] });
          refetch();
          visibleCouponsRefetch();
          refetchMarketplace();
        }, 10);
        setToUpload([]);
      },
    },
  });

  const updateCouponMutation = useUpdateCouponCodeV1CouponCodesCouponCodeIdPatch({
    mutation: {
      onError: (error: AxiosError) => {
        toast.error("Error", { description: parseFastAPIError(error) as ReactNode });
      },
      onSuccess: () => {
        toast("Coupon code status was successfully updated!");
        setTimeout(() => {
          queryClient.invalidateQueries({ queryKey: ["/v1/coupons/"] });
          queryClient.invalidateQueries({ queryKey: ["/v1/marketplace/"] });
          refetch();
          visibleCouponsRefetch();
          refetchMarketplace();
        }, 10);
      },
    },
  });

  useEffect(() => {
    if (coupons.length >= 0) {
      setMappedCodes(
        coupons.map((coupon: CouponCodeModel) => {
          return {
            id: coupon._id,
            code: coupon.code,
            url: coupon.url,
            status: coupon.status,
            user_id: coupon.userId,
          } as Code;
        }),
      );
    }
  }, [coupons]);

  const handleFileChange = async (e: React.ChangeEvent) => {
    const target = e.target as HTMLInputElement;
    const file = target?.files?.[0];
    if (file) {
      try {
        const json = await csvToJson({ headers: ["code", "url"], noheader: true }).fromString(await file.text());
        const transformedData: ToUpload[] = json.map((entity) => {
          // code.toString() is needed in case code consist only from numbers
          return {
            code: entity.code ? entity.code.toString() : null,
            url: entity.url ? entity.url : null,
            offerId: rewardId,
          };
        });
        setToUpload(transformedData);
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
      } catch (_error) {
        toast.error("Error", { description: "Error uploading file" });
      }
    }
  };
  const onFileReset = () => setToUpload([]);
  const onFileUpload = async () => {
    setUploading(true);
    await uploadCouponsMutation.mutateAsync({ data: toUpload });
  };

  async function updateStatus(couponCodeId: string | undefined, value: CouponCodeStatus) {
    if (!couponCodeId) return;
    setMappedCodes((coupons) => {
      return coupons.map((coupon: Code) => {
        if (coupon.id === couponCodeId) return { ...coupon, status: value };
        return coupon;
      });
    });
    await updateCouponMutation.mutateAsync({ couponCodeId: couponCodeId, data: { status: value } });
  }

  const columns = useMemo<ColumnDef<Code>[]>(
    () => [
      {
        id: "select",
        header: ({ table }) => (
          <Checkbox
            checked={table.getIsAllPageRowsSelected() || (table.getIsSomePageRowsSelected() && "indeterminate")}
            onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
            aria-label="Select all"
          />
        ),
        cell: ({ row }) => {
          if (row.original.status === CouponCodeStatus.Unused)
            return <Checkbox checked={row.getIsSelected()} onCheckedChange={(value) => row.toggleSelected(!!value)} aria-label="Select row" />;
        },
        enableSorting: false,
        enableHiding: false,
      },
      {
        accessorKey: "code",
        header: "Code",
      },
      {
        accessorKey: "url",
        header: "Url",
      },
      {
        accessorKey: "status",
        header: "Status",
        cell: ({ row }) => {
          return (
            <Select onValueChange={(value: CouponCodeStatus) => updateStatus(row.original.id, value)} defaultValue={row.original.status}>
              <SelectTrigger>
                <SelectValue placeholder="Status" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value={CouponCodeStatus.Used}>Used</SelectItem>
                <SelectItem value={CouponCodeStatus.Unused}>Unused</SelectItem>
              </SelectContent>
            </Select>
          );
        },
      },
      {
        accessorKey: "user_id",
        header: "User",
      },
    ],
    [coupons],
  );

  const table = useReactTable({
    data: mappedCodes,
    columns: columns,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    initialState: {
      pagination: {
        pageIndex: 0, // custom initial page index
        pageSize: 100, // custom default page size
      },
    },
    state: {
      rowSelection: rowSelection,
    },
    enableRowSelection: (row) => row.original.status === CouponCodeStatus.Unused,
    onRowSelectionChange: setRowSelection,
  });

  const toUploadColumns = useMemo<ColumnDef<ToUpload>[]>(
    () => [
      {
        accessorKey: "code",
        header: "Code",
      },
      {
        accessorKey: "url",
        header: "Url",
      },
      {
        accessorKey: "offerId",
        header: "offerId",
      },
    ],
    [],
  );

  const uploadTable = useReactTable({
    data: toUpload,
    columns: toUploadColumns,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    initialState: {
      pagination: {
        pageIndex: 0, // custom initial page index
        pageSize: 100, // custom default page size
      },
    },
  });

  const bulkDeleteCouponsMutation = useBulkDeleteCouponCodesV1CouponCodesBulkDeleteDelete({
    mutation: {
      onSuccess: () => {
        toast("Coupon codes are now deleted if it was possible");
        setRowSelection({});
        queryClient.invalidateQueries({ queryKey: ["/v1/coupons/"] });
        queryClient.invalidateQueries({ queryKey: ["/v1/marketplace/"] });
        refetch();
        visibleCouponsRefetch();
        refetchMarketplace();
      },
      onError: (error: AxiosError) => {
        toast.error("Error", { description: parseFastAPIError(error) as ReactNode });
      },
    },
  });

  function deleteSelectedCoupons() {
    const selectedRows = table.getSelectedRowModel().rows;

    const selectedIds: string[] = selectedRows.map((row) => row.original.id as string);
    bulkDeleteCouponsMutation.mutate({ data: selectedIds });
  }

  if (!offer) return <SplashPage />;

  return (
    <section className="flex flex-col gap-10 bg-background px-8 py-10">
      <section className="flex items-start justify-between">
        <div className="header-titles_wrapper gap-4">
          <h2 className="text-foreground">Codes</h2>
          <p className="text-foreground">
            Codes for:{" "}
            <Link to={"/$market/marketplace/rewards/$rewardId"} params={{ rewardId: offer._id as string, market: offer.country }}>
              {offer.titleV2} {offer.subTitle}
            </Link>
          </p>
        </div>
        <div className="flex gap-6">
          <label htmlFor="input-excel-file" className={buttonVariants({ variant: "default" })}>
            <Icon icon="Add" /> Add codes
            <input
              id="input-excel-file"
              type="file"
              autoComplete="off"
              accept=".csv,text/csv"
              className="hidden bg-background text-foreground"
              onChange={handleFileChange}
              disabled={isFetching}
            />
          </label>

          <CSVLink
            className={buttonVariants({ variant: "outline" })}
            data={unusedCoupons.map((coupon: CouponCodeModel) => [coupon.code || "", coupon.url || ""])}
            target="_blank"
            filename={`unused-coupons-${offer?.titleV2?.replace(/\s+/g, "-").toLowerCase()}-${offer?.subTitle?.replace(/\s+/g, "-").toLowerCase()}.csv`}
          >
            <Icon icon="Download" /> Download unused codes
          </CSVLink>
          {Object.keys(rowSelection).length > 0 && (
            <Button variant="destructive" onClick={() => setOpenConfirmDialog(true)}>
              Delete Coupon Codes
            </Button>
          )}
        </div>
      </section>
      <div>
        <section className="coupon-code-container">
          <section className="button-group"></section>
          {toUpload.length === 0 && (
            <div className="flex flex-col gap-10">
              <form className="flex w-full flex-col gap-10 rounded-lg bg-accent p-4">
                <Input
                  placeholder="Search"
                  value={(table.getColumn("code")?.getFilterValue() as string) ?? ""}
                  onChange={(event) => table.getColumn("code")?.setFilterValue(event.target.value)}
                />
                <div className="grid w-full grid-cols-5 items-center gap-10">
                  <Select
                    onValueChange={(value) =>
                      navigate({
                        to: "/$market/marketplace/rewards/$rewardId/coupons",
                        params: { market: market, rewardId: rewardId },
                        search: { status: value },
                      })
                    }
                    defaultValue={search.status}
                  >
                    <SelectTrigger>
                      <SelectValue placeholder="Status" />
                    </SelectTrigger>
                    <SelectContent>
                      <SelectItem value="Used">Used</SelectItem>
                      <SelectItem value="Unused">Unused</SelectItem>
                    </SelectContent>
                  </Select>
                </div>
              </form>
              <Card>
                <CardContent className="p-8">
                  <DataTable table={table} columns={columns} loading={isLoading || isFetching} />
                </CardContent>
              </Card>
            </div>
          )}

          {toUpload.length > 0 && (
            <div className="flex flex-col gap-10">
              <h2>Preview of coupons to be uploaded</h2>
              <Card>
                <CardContent className="p-8">
                  <DataTable table={uploadTable} columns={toUploadColumns} hidePagination />
                </CardContent>
              </Card>

              <section className="flex justify-end gap-6">
                <Button variant="outline" onClick={onFileReset} disabled={uploading}>
                  Cancel
                </Button>
                <Button onClick={onFileUpload} disabled={uploading}>
                  Upload coupons
                </Button>
              </section>
            </div>
          )}
        </section>
      </div>
      <ConfirmDialog
        openDialog={openConfirmDialog}
        setOpenDialog={setOpenConfirmDialog}
        title={`Are you sure you want to delete ${Object.keys(rowSelection).length} coupons?`}
        confirmAction={deleteSelectedCoupons}
        confirmText="Delete"
      />
    </section>
  );
}
